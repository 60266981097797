import styled from 'styled-components';
import { longDateTime } from '../../../utils/dataFormat';

const DateTime = styled.span`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #343A40;
`;

const getColumns = () => [
  {
    id: 'created_at',
    Header: 'Datum',
    accessor: 'created_at',
    Cell: ({value}) => <DateTime>{longDateTime(value)}</DateTime>,
  },
  {
    id: 'type',
    Header: 'Akce',
    accessor: 'type',
  },
  {
    id: 'data',
    Header: 'Informace',
    accessor: 'data',
    Cell: ({ value }) => '-',
  },
];

export default getColumns;