import { useState } from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import Input from '../Forms/input';
import Button from '../Button';
import { ReactComponent as SearchSvg } from '../../assets/icons/icon-search.svg';

const SearchIcon = styled(SearchSvg)`
  width: 30px;
  height: auto;
  position: absolute;
  top: 11px;
  left: 18px;
  fill: #CED4DA;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  ${Button} {
    height: 50px;
    min-width: 96px;
  }

  ${Input} {
    height: 50px;
    width: 300px;
    padding-left: 4rem;
    margin-right: 0.375rem;
  }
`;

function Search({onSearch}) {
  const [value, setValue] = useState('');

  const handleSearch = () => {
    if(value) {
      console.log('witke search invoked ...', value);
      onSearch(value);
    }
  }

  return (
    <Wrapper>
      <InputWrapper>
        <SearchIcon />
        <Input placeholder="Zadejte text..." onChange={e => setValue(e.target.value)} />
      </InputWrapper>
      <Button onClick={handleSearch}>Hledej</Button>
    </Wrapper>
  );
}

Search.propTypes = {
  onSearch: func.isRequired,
};

export default Search;