import styled from 'styled-components';
import useAuth from '../../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AvatarSvg } from '../../assets/icons/icon-avatar.svg';
import { USER_PROFILE } from '../../routes';

const AvatarIcon = styled(AvatarSvg)`
  height: 60px;
  width: 60px;

  cursor: pointer;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
  column-gap: 1rem;
  align-items: center;
  margin-bottom: 4.375rem;
`;

const Name = styled.div`
  color: #F8F9FA;
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
  cursor: pointer;
`;

function UserAvatar() {
  const history = useHistory();
  const {user: { name, surname, role }} = useAuth();

  const showUserProfile = () => {
    history.push(USER_PROFILE);
  }

  // TODO: show pointer only for USER

  return (
    <Wrapper>
      <AvatarIcon onClick={() => role === 'USER' && showUserProfile()} />
      <Name onClick={() => role === 'USER' && showUserProfile()}>
        {`${name} ${surname}`}
      </Name>
    </Wrapper>
  );
}

export default UserAvatar;