// export const API_URL = 'http://localhost:3003/gfs-rest/public/api/v1';
export const API_URL = 'https://gfs-api.estyholding.cz/api/v1'
export const USER_DATA_KEY = 'auth-usr';

export const USER_REQUEST_TYPE = [
  // 'NEW_ACCOUNT',
  // 'PASSWORD_RECOVERY',
  'USER_INFO_CHANGE',
  'OTHER'
];

export const CAREER_ROLE = [
  'TRAINEE',
  'FINANCIAL CONSULTANT',
  'FINANCIAL SPECIALIST',
  'HEAD FINANCIAL CONSULTANT',
  'TEAM MANAGER',
  'AREA MANAGER',
  'AREA DIRECTOR',
  'REGIONAL DIRECTOR',
  'GENERAL DIRECTOR',
  'PARTNER'
];