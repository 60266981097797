import { useEffect, useState, useMemo } from 'react';
import { notification } from 'antd';
import styled from 'styled-components';
import DataTable from '../DataTable';
import { getAllNew, getAllCompleted } from '../../services/userRequestService';
import getColumns from './columns';
import SectionTitle from '../SectionTitle';
import Panel from '../common/panel';
import Container from '../common/container';
import TopPanel from '../TopPanel';
import PanelContent from './panelContent';
import RequestDetail from './requestDetail';

const Subtitle = styled.div`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #343A40;
  margin-bottom: 1.25rem;
`;

function UserRequests() {
  const [dataNew, setDataNew] = useState([]);
  const [isLoadingNew, setLoadingNew] = useState(true);
  const [errorNew, setErrorNew] = useState(undefined);
  const [dataCompleted, setDataCompleted] = useState([]);
  const [isLoadingCompleted, setLoadingCompleted] = useState(true);
  const [errorCompleted, setErrorCompleted] = useState(undefined);
  const [selectedRequest, setSelectedRequest] = useState(null);

  const columnsNew = useMemo(() => getColumns({setSelectedRequest}), [setSelectedRequest]);
  const columnsCompleted = useMemo(() => getColumns({setSelectedRequest}), [setSelectedRequest]);

  const loadDataNew = () => {
    setLoadingNew(true);
    setErrorNew(undefined);
    getAllNew()
      .then( data => {
        setDataNew(data);
      })
      .catch(error => {
        setErrorNew(error);
      })
      .then(() => setLoadingNew(false));
  }

  const loadDataCompleted = () => {
    setLoadingCompleted(true);
    setErrorCompleted(undefined);
    getAllCompleted()
      .then( data => {
        setDataCompleted(data);
      })
      .catch(error => {
        setErrorCompleted(error);
      })
      .then(() => setLoadingCompleted(false));
  }


  useEffect(() => {
    loadDataNew();
    loadDataCompleted();
  }, []);

  return (
    <>
    {
      selectedRequest && (
        <RequestDetail
          id={selectedRequest}
          onClose={() => setSelectedRequest(null)}
          invokeReload={() => {
            const reqId = selectedRequest.id;

            notification['success']({
              message: 'Aktualizace požadavku',
              description:
                `Stav požadavku č. ${reqId} byl úspěšně aktualizován.`,
            });
            setSelectedRequest(null);
            loadDataNew();
            loadDataCompleted();
          }} />
      )
    }

    <TopPanel contentPadding="0.875rem 0">
      <PanelContent
        onSearch={() => console.log('witke todo search')}
      />
    </TopPanel>
    <Container>
    <Panel>
      <SectionTitle>
        Správa požadavků
      </SectionTitle>
      <Subtitle>
        Otevřené požadavky
      </Subtitle>
      <DataTable
        columns={columnsNew}
        data={dataNew}
        loading={isLoadingNew}
        error={!!errorNew}
      />

      <Subtitle>Vyřešené požadavky</Subtitle>
        <DataTable
          columns={columnsCompleted}
          data={dataCompleted}
          loading={isLoadingCompleted}
          error={!!errorCompleted}
        />
    </Panel>
    </Container>
    </>
  );
}

export default UserRequests;