import { useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Form, Field } from 'react-final-form';
import Button from '../Button';
import { InputWrapper } from '../Forms/common';
import Label from '../Forms/label';
import Input from '../Forms/input';
import Message from '../Forms/message';
import { sendRegistrationRequest } from '../../services/authService';
import { validateFormValues } from '../../utils/formValidation';
import ActionResult from '../ActionResult';
import { EMAIL_FORMAT, FIELD_REQUIRED } from '../../utils/validationMessages';
import PageLoader from '../Loaders/pageLoader';

const schema = yup.object().shape({
  name: yup.string().required(FIELD_REQUIRED),
  surname: yup.string().required(FIELD_REQUIRED),
  email: yup.string().required(FIELD_REQUIRED).email(EMAIL_FORMAT),
  phone: yup.string().required(FIELD_REQUIRED),
});

const Title = styled.div`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 700;
  text-align: center;
  color: ${({theme}) => theme.colors.text_primary};
  margin-bottom: 1.875rem;

  @media (min-width: 768px) {
    font-size: 2.25rem;
    line-height: 2.875rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.875rem;
`;

const Text = styled.div`
  font-size: 0.75rem;
  line-height: 0.875rem;
  font-weight: 500;
  color: #6c757d;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const BottomPanel = styled.div`
  margin-bottom: 0.125rem;

  ${Button} {
    margin-bottom: 0.375rem;
  }

  ${ActionResult} {
    text-align: center;
    margin-bottom: 1.875rem;
  }
`;

function RegistrationForm({showLoginForm}) {
  const [showResult, setShowResult] = useState(false);
  const [showError, setShowError] = useState(false);

  const onSubmit = (values) => {
    setShowError(false);
    setShowResult(false)

    return sendRegistrationRequest(values)
      .then(data => {
        setShowResult(true);
      })
      .catch(error => {
        setShowError(true);
      })
  }

  return (
    <>
      <Title>Žádost o registraci</Title>
      <Form
        onSubmit={onSubmit}
        validate={validateFormValues({schema})}
        initialValues={{
          name: '',
          surname: '',
          email: '',
          phone: '',
        }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
          <Grid>
            { submitting && <PageLoader /> }
             <Field
                name="name"
                render={({ input, meta }) => (
                  <InputWrapper>
                    <Label>Jméno:</Label>
                    <Input type="text" {...input} {...meta} disabled={submitting} />
                    {meta.touched && meta.error && <Message isError>{meta.error}</Message>}
                  </InputWrapper>
                )}
              />

              <Field
                name="surname"
                render={({ input, meta }) => (
                  <InputWrapper>
                    <Label>Příjmení</Label>
                    <Input type="text" {...input} {...meta} disabled={submitting} />
                    {meta.touched && meta.error && <Message isError>{meta.error}</Message>}
                  </InputWrapper>
                )}
              />

              <Field
                name="email"
                render={({ input, meta }) => (
                  <InputWrapper>
                    <Label>E-mail</Label>
                    <Input type="text" {...input} {...meta} disabled={submitting} />
                    {meta.touched && meta.error && <Message isError>{meta.error}</Message>}
                  </InputWrapper>
                )}
              />

              <Field
                name="phone"
                render={({ input, meta }) => (
                  <InputWrapper>
                    <Label>Telefon</Label>
                    <Input type="text" {...input} {...meta} disabled={submitting} />
                    {meta.touched && meta.error && <Message isError>{meta.error}</Message>}
                  </InputWrapper>
                )}
              />
              <BottomPanel>
                { showResult && <ActionResult>Žádost byla úspěšně odeslána</ActionResult> }
                { showError && <ActionResult isError>Při odeslání žádosti došlo k chybě</ActionResult> }
                <Button type="submit" disabled={submitting}>
                  Odeslat žádost
                </Button>
              </BottomPanel>
          </Grid>
          </form>
        )}
      />
      <Text><span onClick={showLoginForm}>Zpět na přihlášení</span></Text>
    </>
  );
}

export default RegistrationForm;