import styled from 'styled-components';

export default styled.div`
   font-family: 'DM Sans', Helvetica, Arial, sans-serif;
   line-height: 1.375rem;
   font-weight: 500;
   color: ${({isBold}) => isBold ? '#6C757D' : '#ADB5BD'};

   a {
      color: #6C757D;
      text-decoration: none;
   }

   a:hover {
      text-decoration: underline;
   }
`;