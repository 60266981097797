import styled from 'styled-components';
import Label from './label';
import Message from './message';

export const InputWrapper = styled.div`
  ${Label} {
    display: block;
    margin-bottom: 0.375rem;
  }

  ${Message} {
    margin-top: 0.375rem;
  }
`;