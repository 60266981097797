import { useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Form, Field } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { login } from '../../services/authService';
import { handleAuthResponse } from '../../utils/auth';
import { validateFormValues } from '../../utils/formValidation';
import { DASHBOARD } from '../../routes';
import PasswordForm from './passwordForm';
import Button from '../Button';
import { InputWrapper } from '../Forms/common';
import Label from '../Forms/label';
import Message from '../Forms/message';
import DefaultInput from '../Forms/input';
import { FIELD_REQUIRED } from '../../utils/validationMessages';
import ActionResult from '../ActionResult';
import PageLoader from '../Loaders/pageLoader';
import { ReactComponent as UserSvg } from '../../assets/icons/icon-input-user.svg';
import { ReactComponent as PasswordSvg } from '../../assets/icons/icon-input-password.svg';

const schema = yup.object().shape({
  username: yup.string().required(FIELD_REQUIRED),
  pwd: yup.string().required(FIELD_REQUIRED),
});

const UserIcon = styled(UserSvg)`
  display: none;
  width: 36px;
  height: auto;
  position: absolute;
  top: 12px;
  left: 18px;
  fill: ${({isError, theme}) => isError ? theme.colors.error : '#CED4DA'};

  @media (min-width: 768px) {
    display: block;
  }
`;

const PasswordIcon = styled(PasswordSvg)`
  display: none;
  width: 36px;
  height: auto;
  position: absolute;
  top: 12px;
  left: 18px;
  fill: ${({isError, theme}) => isError ? theme.colors.error : '#CED4DA'};

  @media (min-width: 768px) {
    display: block;
  }
`;

const InputFieldWrapper = styled.div`
  position: relative;
`;

const Input = styled(DefaultInput)`
  text-align: left;
  width: 100%;
  height: 50px;
  text-align: center;

  @media (min-width: 768px) {
    height: 60px;
    padding-left: 4.5rem;
    text-align: left;
  }
`;

const Text = styled.div`
  font-size: 0.75rem;
  line-height: 0.875rem;
  font-weight: 500;
  color: #6c757d;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const Title = styled.div`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 700;
  text-align: center;
  color: ${({theme}) => theme.colors.text_primary};
  margin-bottom: 1.875rem;

  @media (min-width: 768px) {
    font-size: 2.25rem;
    line-height: 2.875rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.875rem;
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 0.125rem;

  ${Text}:last-child {
    margin-top: 0.5rem;
  }

  @media (min-width: 768px) {
    flex-direction: row;

    ${Text}:last-child {
      margin-top: 0;
    }
  }
`;

const BottomPanel = styled.div`
  ${Button} {
    margin-bottom: 0.375rem;
  }

  ${ActionResult} {
    text-align: center;
    margin-bottom: 1.875rem;
  }
`;

function LoginForm({showRegistrationMode}) {
  const [showError, setShowError] = useState(false);
  const [showForgottenPwd, setShowForgottenPwd] = useState(false);
  const history = useHistory();

  const onSubmit = ({ username, pwd }) => {
    setShowError(false);

    return login(username, pwd)
      .then(data => {
        handleAuthResponse(data);
        history.push(DASHBOARD);
      })
      .catch(error => {
        setShowError(true);
      });
  }

  return (
    <>

    {!showForgottenPwd && (
      <>
        <Title>Přihlásit se</Title>
        <Form
          onSubmit={onSubmit}
          validate={validateFormValues({schema})}
          initialValues={{
            username: '',
            pwd: '',
          }}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
            <Grid>
              { submitting && <PageLoader />}
              <Field
                  name="username"
                  render={({ input, meta }) => (
                    <InputWrapper>
                      <Label>E-mail:</Label>
                      <InputFieldWrapper>
                        <UserIcon isError={meta.touched && meta.error} />
                        <Input type="text" {...input} {...meta} autoComplete="off" disabled={submitting}  />
                      </InputFieldWrapper>
                      {meta.touched && meta.error && <Message isError>{meta.error}</Message>}
                    </InputWrapper>
                  )}
                />

                <Field
                  name="pwd"
                  render={({ input, meta }) => (
                    <InputWrapper>
                      <Label>Heslo:</Label>
                      <InputFieldWrapper>
                        <PasswordIcon isError={meta.touched && meta.error}  />
                        <Input type="password" {...input} {...meta} disabled={submitting} />
                      </InputFieldWrapper>
                      {meta.touched && meta.error && <Message isError>{meta.error}</Message>}
                    </InputWrapper>
                  )}
                />
                <BottomPanel>
                  { showError && <ActionResult isError>Zadáno špatné uživatelské jméno nebo heslo!</ActionResult>}
                  <Button type="submit" disabled={submitting}>
                    Přihlásit se
                  </Button>
                  <ActionsWrapper>
                    <Text>
                      <span onClick={() => setShowForgottenPwd(true)}>Zapomněl jste své heslo?</span>
                    </Text>
                    <Text>
                      <span onClick={showRegistrationMode}>Zaslat žádost o registraci</span>
                    </Text>
                  </ActionsWrapper>
                </BottomPanel>
            </Grid>
            </form>
          )}
        />
      </>
    )}
    {
      showForgottenPwd && (
        <>
          <Title>Zapomenuté heslo</Title>
          <PasswordForm />
          <Text onClick={() => setShowForgottenPwd(false)}>Zpět na přihlášení</Text>
        </>
      )
    }
    </>
  );
}

export default LoginForm;