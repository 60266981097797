import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';
import ProtectedRoute from '../Authentication/protectedRoute';
import Dashboard from '../Dashboard';
import { DASHBOARD, HOME, ADMIN_USERS, ADMIN_REQUESTS, USER_PROFILE, DOCUMENTS, COMPETITIONS, COMPETITION_DETAIL, ADMIN_USER_NEW, ADMIN_USER_DETAIL, EXAMS, EXAM_DETAIL, ADMIN_EXAMS } from '../../routes';
// import { GENERATE_PDF, HOME, LEARNING, DOCUMENTS, USER_PROFILE, ADMIN_USERS, PROPERTY_TIP, BROKER_TIP, TIPS, PROPERTIES, ADMIN_TIPS_BROKER, ADMIN_TIPS_PROPERTY } from '../../routes';
// import Learning from '../Learning';
import Documents from '../Documents';
// import GeneratePdf from '../GeneratePdf';
import Sidebar from '../Sidebar';
import TopBar from '../TopBar';
// import Footer from '../Footer';
import UserProfile from '../UserProfile';
import AdminExams from '../Admin/Exams';
import Users from '../Admin/Users';
import UserRequests from '../UserRequests';
import Competitions from '../Competitions';
import CompetitionDetail from '../CompetitionDetail';
import NewUser from '../Admin/NewUser';
import UserDetail from '../Admin/UserDetail';
import Exams from '../Exams';
import ExamDetail from '../ExamDetail';

const Wrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
  flex: 1 1 0;
  /* display: flex; */
  position: relative;
`;
const Content = styled.div`
  margin-left: 0px;
  overflow: hidden;
  position: relative;
  /* padding: 0 0.5rem 2px 0.5rem; */
  /* margin-top: 100px; */

  @media(min-width: 576px) {
    margin-left: 64px;
    /* padding: 0 1.5rem 2px 1.5rem; */
  }

  @media(min-width: 768px) {
    margin-left: 320px;
    /* padding: 3.125rem 3.75rem; */
  }
`;

// export const ToolBarContext = React.createContext();

function Home() {

  return (
    <Wrapper>
    <Sidebar />
    <TopBar />
      <Content>
        <Switch>
          <ProtectedRoute exact path={DASHBOARD} roles={['ADMIN', 'USER']} component={Dashboard} />
          <ProtectedRoute exact path={ADMIN_EXAMS} roles={['ADMIN']} component={AdminExams} />
          <ProtectedRoute exact path={ADMIN_USERS} roles={['ADMIN']} component={Users} />
          <ProtectedRoute exact path={ADMIN_USER_NEW} roles={['ADMIN']} component={NewUser} />
          <ProtectedRoute exact path={ADMIN_USER_DETAIL} roles={['ADMIN']} component={UserDetail} />
          <ProtectedRoute exact path={ADMIN_REQUESTS} roles={['ADMIN']} component={UserRequests} />
          <ProtectedRoute exact path={USER_PROFILE} roles={['USER']} component={UserProfile} />
          <ProtectedRoute exact path={DOCUMENTS} roles={['ADMIN', 'USER']}  component={Documents} />
          <ProtectedRoute exact path={COMPETITIONS} roles={['ADMIN', 'USER']}  component={Competitions} />
          <ProtectedRoute exact path={COMPETITION_DETAIL} roles={['ADMIN', 'USER']}  component={CompetitionDetail} />
          <ProtectedRoute exact path={EXAMS} roles={['USER']}  component={Exams} />
          <ProtectedRoute exact path={EXAM_DETAIL} roles={['USER']}  component={ExamDetail} />
          {/*
          <ProtectedRoute exact path={LEARNING} roles={['ADMIN', 'MAKLER']}  component={Learning} />
          <ProtectedRoute exact path={DOCUMENTS} roles={['ADMIN', 'MAKLER']}  component={Documents} />
          <ProtectedRoute exact path={GENERATE_PDF} roles={['ADMIN', 'MAKLER']}  component={GeneratePdf} />
          <ProtectedRoute exact path={PROPERTY_TIP} roles={['ADMIN', 'PORADCE']} component={PropertyTip} />
          <ProtectedRoute exact path={BROKER_TIP} roles={['PORADCE']} component={BrokerTip} />
          <ProtectedRoute exact path={TIPS} roles={['PORADCE']} component={MyTips} />
          <ProtectedRoute exact path={PROPERTIES} roles={['MAKLER']} component={MyProperties} />
          <ProtectedRoute exact path={ADMIN_USERS} roles={['ADMIN']} component={Users} />
          <ProtectedRoute exact path={ADMIN_TIPS_BROKER} roles={['ADMIN']} component={TipsBroker} />
          <ProtectedRoute exact path={ADMIN_TIPS_PROPERTY} roles={['ADMIN']} component={TipsProperty} /> */}
          {/* <Route path="*" component={() => <Redirect to={HOME} />} /> */}
        </Switch>
      </Content>
      </Wrapper>
  );
}

export default Home;