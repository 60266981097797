import { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { getAll } from '../../../services/examService';
import Container from '../../common/container';
import TopPanel from '../../TopPanel';
import PanelContent from './panelContent';
import DataTable from '../../DataTable';
import getColumns from './columns';
import Panel from '../../common/panel';
import SectionTitle from '../../SectionTitle';
import DetailModal from './detailModal';
import ResultModal from './resultModal';

// const Subtitle = styled.div`
//   font-family: 'DM Sans', Helvetica, Arial, sans-serif;
//   font-weight: 700;
//   font-size: 1.125rem;
//   line-height: 1.5rem;
//   color: #343A40;
//   margin-bottom: 1.25rem;
//   margin-left: 3rem;
// `;

const List = styled.div`
  display: grid;
  /* grid-template-columns: repeat(4, 1fr); */
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1.25rem;
  row-gap: 1.25rem;
  margin-bottom: 3.125rem;
`;

function Exams() {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalType, setModalType] = useState(null);

  const showExamResults = useCallback((id) => {
    setSelectedItem(id);
    setModalType('SHOW_RESULTS');
  }, [setSelectedItem, setModalType]);

  const showExamPreview = useCallback((id) => {
    setSelectedItem(id);
    setModalType('SHOW_PREVIEW');
  }, [setSelectedItem, setModalType]);

  const columns = useMemo(() => getColumns({showExamResults, showExamPreview}), [showExamResults, showExamPreview]);

  const loadData = () => {
    setLoading(true);
    setError(undefined);
    getAll()
      .then( data => {
        setData(data);
      })
      .catch(error => {
        setError(error);
      })
      .then(() => setLoading(false));
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <DetailModal
         isOpen={!!selectedItem && modalType === 'SHOW_PREVIEW'}
         selectedItem={selectedItem}
         onClose={() => {
          setSelectedItem(null);
          setModalType(null)
        }} />

      <ResultModal
        isOpen={!!selectedItem && modalType === 'SHOW_RESULTS'}
        selectedItem={selectedItem}
        onClose={() => {
          setSelectedItem(null);
          setModalType(null)
        }} />

      <TopPanel>
        <PanelContent />
      </TopPanel>
      <Container>
        <Panel>
          <SectionTitle>Správa kurzů</SectionTitle>
          <DataTable
            columns={columns}
            data={data}
            loading={isLoading}
            error={!!error}
          />
        </Panel>
      </Container>
    </>
  );
}

export default Exams;