import styled from "styled-components";
import { ReactComponent as PdfSvg } from '../../assets/icons/icon-type-pdf.svg';
import { ReactComponent as ArrowSvg } from '../../assets/icons/icon-arrow-right.svg';
import fileDownload from 'js-file-download';
import { getDocument } from "../../services/examService";

const PdfIcon = styled(PdfSvg)`
  width: 30px;
  height: auto;
  fill: #EA650D;
`;

const ArrowIcon = styled(ArrowSvg)`
  width: 20px;
  height: auto;
  fill: #CED4DA;
`;

const Separator = styled.div`
  max-width: 300px;
  margin: 0 auto;
  height: 2px;
  border-radius: 2px;
  background-color: #EA650D;
  margin-bottom: 3rem;
`;

const Wrapper = styled.div`
  margin-bottom: 2rem;
  max-width: 700px;
  /* margin: 0 auto; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
`;

const Title = styled.p`
font-family: 'DM Sans';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 23px;
text-align: center;
color: #343A40;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: rgba(233, 236, 239, 0.5);
  border-radius: 2px;

  span {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #343A40;
  }

  &:hover {
    cursor: pointer;
    background: rgba(233, 236, 239, 0.85);
  }

  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${PdfIcon} {
    margin-right: 2rem;
  }
`;

// TODO handle download error
function Documents({id, data}) {

  const handleDownload = doc => {
    getDocument(id, doc.id)
      .then(async res => await fileDownload(res, `${doc.path}.${doc.type}`));
  }

  return (
    <Wrapper>
      <Separator />
      <Title>Materiály ke studiu</Title>
      {
        data.map(it => (
          <Item key={it.id} onClick={() => handleDownload(it)}>
            <IconWrapper>
              <PdfIcon />
              <span>{it.label}</span>
            </IconWrapper>
            <ArrowIcon />
          </Item>
        ))
      }
    </Wrapper>
  );
}

export default Documents;