import { useEffect, useState } from "react";
import styled from "styled-components";
import { bool, func } from 'prop-types';
import { Modal } from 'antd';
import CloseIcon from '../../Modal/closeIcon';
import SectionTitle from '../../SectionTitle';
import { getResults } from "../../../services/examService";
import Loader from "../../Loaders/componentLoader";
import { longDateTime } from "../../../utils/dataFormat";

const SectionLabel = styled.span`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #343A40;
  margin-bottom: 1rem;
  display: block;
`;

const Info = styled.div`
  font-size: 1.25rem;
`;

const DetailWrapper = styled.div`
  margin-top: 1rem;
`;

const QuestionDetail = styled.div`
  background-color: rgba(233, 236, 239, 0.5);
  border-radius: 2px;
  padding: 0.5rem 1rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const QuestionLabel = styled.div`
  font-weight: bold ;
  font-size: 1rem;
`;

const Option = styled.span`
  display: block;
  margin: 0.25rem 0;
  color: ${({isCorrect}) => isCorrect ? '#29F2AD' : 'inherit'};
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 0.5rem;
  padding: 1rem 2rem;
  align-items: center;

  background: #FDFDFD;

  border: 2px solid #CED4DA;
  border-radius: 8px;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 2rem;
  }

  ${({isSuccess}) => isSuccess && `
    border-color: #29F2AD;
  `};

  ${({isFailure}) => isFailure && `
    border-color: #FF6158;
  `};
`;

const Name = styled.span`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #343A40;
`;

const Points = styled.span`
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
color: #6C757D;

span {
  color: #343A40;
  font-weight: 500;
  }
`;

const FinishedDate = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #6C757D;
`;
// TODO handle error
function ResultModal({isOpen, selectedItem, onClose}) {

  const [okRows, setOkRows] = useState([]);
  const [failRows, setFailRows] = useState([])
  const [isLoading, setLoading] = useState(true);

  console.log('witke sel', selectedItem);

  useEffect(() => {
    if(isOpen) {
      getResults(selectedItem.id)
        .then(data => {
          setOkRows(data.filter(item => item.state === 'SUCCESS'));
          setFailRows(data.filter(item => item.state === 'FAILURE'));
        })
        .then(() => setLoading(false));
    }
  }, [isOpen, selectedItem]);

  return(
    <Modal
      bodyStyle={{padding: '1.875rem 3.125rem'}}
      visible={isOpen}
      onCancel={onClose}
      footer={null}
      width={800}
      closeIcon={<CloseIcon />}
      destroyOnClose={true}
      afterClose={() => {
        // setData(null);
        // setResults([]);
      }}
      >
      <SectionTitle>Přehled výsledků kurzu</SectionTitle>
      {
        isLoading && (
          <Loader />
        )
      }

      {!isLoading && (
        <>
          <Info>Název: <strong>{selectedItem?.name}</strong></Info>
          <Info>{`${selectedItem?.points} bodů, ${selectedItem?.duration} min`}</Info>

          <DetailWrapper>
            <SectionLabel>Splnili:</SectionLabel>
            <div>
            {
              okRows.length === 0 && (
                <p>- seznam je prázdný -</p>
              )
            }
            {
              okRows.map(item => (
                <Row key={item.id} isSuccess>
                  <Name>{`${item.name} ${item.surname}`}</Name>
                  <Points><span>{item.points}</span>/{selectedItem?.points} bodů</Points>
                  <FinishedDate>{longDateTime(item.finished_at)}</FinishedDate>
                </Row>
              ))
            }
            </div>

            <SectionLabel>Nesplnili:</SectionLabel>
            <div>
            {
              failRows.length === 0 && (
                <p>- seznam je prázdný -</p>
              )
            }
            {
              failRows.map(item => (
                <Row key={item.id} isFailure>
                  <Name>{`${item.name} ${item.surname}`}</Name>
                  <Points><span>{item.points}</span>/{selectedItem?.points} bodů</Points>
                  <FinishedDate>{longDateTime(item.finished_at)}</FinishedDate>
                </Row>
              ))
            }
            </div>
          </DetailWrapper>
        </>
      )}

    </Modal>
  );
}

ResultModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
}

export default ResultModal;