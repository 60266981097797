
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { getDetail, getProfile } from '../../../services/userService';
import { shortDate } from '../../../utils/dataFormat';
import Container from '../../common/container';
import { ReactComponent as AvatarSvg } from '../../../assets/icons/icon-avatar.svg';
import TopPanel from '../../TopPanel';
import Item from '../../BreadcrumbNav/item';
import PageLoader from '../../Loaders/pageLoader';
import { ADMIN_USERS } from '../../../routes';
import BreadcrumbNav from '../../BreadcrumbNav';
import UserLogs from './userLogs';

const AvatarIcon = styled(AvatarSvg)`
  height: 200px;
  width: 200px;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #ADB5BD;
  margin-bottom: 0.25rem;
`;

const Value = styled.div`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #343A40;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  background-color: #FDFDFD; //${({theme}) => theme.colors.white};
  box-shadow: 2px 2px 13px rgba(33, 37, 41, 0.15);
  border-radius: 2px;
  margin: 5rem auto 0;
  padding: 0 5rem 2.5rem;
  position: relative;
  min-height: 350px;
`;

const AvatarWrapper = styled.div`
  text-align: center;
  /* border: 1px solid red; */
  position: relative;
  margin-bottom: 1.875rem;

  ${AvatarIcon} {
    margin-top: -30px;
  }
`;

const Name = styled.div`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #212529;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
`;

const Position = styled.div`
   font-family: 'DM Sans', Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #6C757D;
    font-size: 1.125rem;
    line-height: 1.5rem;
    text-align: center;
`;

const Info = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.25rem;
  row-gap: 1.875rem;
  margin-top: 1.875rem;
  margin-bottom: 3.125rem;
`;

function UserDetail() {
  const [data, setData] = useState(null);
  const [navItems, setNavItems] = useState([{
    key: 'home',
    label: 'Přehled uživatelů',
    link: ADMIN_USERS,
  },
  {
    key: 'current',
    label: '-',
  },])
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {id: userId } = useParams();

  const loadData = () => {
    setLoading(false);
    setError(false);

    getDetail(userId)
      .then(data => {
        setData(data);
        setNavItems([navItems[0], {
          key: 'current',
          label: `${data.name} ${data.surname}`,
        }])
      })
      .catch(error => {
        setError(false);
      })
      .then(() => setLoading(false));
  }

  useEffect(() => {
    loadData(userId);
  }, [userId]);

  if(isLoading || !data) {
    return (
      <Wrapper>
        <PageLoader />
      </Wrapper>
    )
  }

  // TODO: display loading and error state
  // TODO: career status string
  // TODO: parent user name
  // TODO: avatar icon bg
  return (
    <>
    <TopPanel>
      <BreadcrumbNav items={navItems} />
    </TopPanel>
      <Container>
        <Wrapper>
          {(isLoading || !data) ? (
            <PageLoader />
          ): (
            <>
              <AvatarWrapper>
                <AvatarIcon />
              </AvatarWrapper>
              <Name>{`${data.name} ${data.surname}`}</Name>
              <Position>{data.career_status}</Position>
              <Info>
                <div>
                  <Label>E-mail:</Label>
                  <Value>{data.email}</Value>
                </div>
                <div>
                  <Label>Telefon:</Label>
                  <Value>{data.phone}</Value>
                </div>
                <div>
                  <Label>Nadřazený uživatel:</Label>
                  <Value>{data.parent_id ? `${data.parent_name} ${data.parent_surname}` : '-'}</Value>
                </div>
                <div>
                  <Label>Datum registrace:</Label>
                  <Value>{shortDate(data.created_at)}</Value>
                </div>
              </Info>
            </>
          )}

          <UserLogs id={userId} />
        </Wrapper>
      </Container>
    </>
  );
}

export default UserDetail;