import styled from 'styled-components';
import Search from '../Search';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.span`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #6C757D;
`;

function PanelContent() {
  return (
    <Wrapper>
      <Label>Soutěže</Label>
    </Wrapper>
  );
}

export default PanelContent;