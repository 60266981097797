import { useState, useEffect } from 'react';
import styled from "styled-components";
import { ReactComponent as CloseSvg } from '../../assets/icons/icon-close.svg';
import { ReactComponent as CheckSvg } from '../../assets/icons/icon-check.svg';

const ResultIcon = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({success}) => success ? '#29F2AD' : '#FF6158'};
`;

const FailIcon = styled(CloseSvg)`
  width: 35px;
  height: auto;
  fill: #fff;
`;

const CheckIcon = styled(CheckSvg)`
  width: 50px;
  height: auto;
  fill: #fff;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  margin: 2rem 0;
`;

const ResultWrapper = styled.div`
  grid-column: span 2;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(233, 236, 239, 0.5);
  border-radius: 8px;
`;

const ResultTitle = styled.p`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  margin-bottom: 0.5rem;
`;

const PointsInfo = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  color: ${({success}) => success ? '#29F2AD' : '#FF6158'};
  margin-bottom: 1rem;
`;

const ResultMessage = styled.p`
  margin-bottom: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #6C757D;

  strong {
    color: #212529;
  }
`;

const PointsInfoItem = styled.div`
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(233, 236, 239, 0.5);
  border-radius: 8px;
  font-family: 'DM Sans';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 23px;
color: #343A40;

${FailIcon} {
  width: 20px;
  margin-right: 1.5rem;
  fill: #FF6158;
}

${CheckIcon} {
  width: 30px;
  margin-right: 1.5rem;
  fill: #29F2AD;
}

p {
  margin: 0;
}

  span {
    color: ${({success}) => success ? '#29F2AD' : '#FF6158'};
  }
`;

function ResultPanel({result}) {
  const [correct, setCorrect] = useState(0);
  const [failed, setFailed] = useState(0);

  const getCorrectLabel = count => {
    if(count === 1) {
      return <p>{ count }&nbsp;<span>správná</span> odpověď</p>
    }

    if(count > 1 && count < 5) {
      return <p>{ count }&nbsp;<span>správné</span> odpovědi</p>
    }

    return <p>{ count }&nbsp;<span>správných</span> odpovědí</p>
  }

  const getFailedLabel = count => {
    if(count === 1) {
      return <p>{ count }&nbsp;<span>špatná</span> odpověď</p>
    }

    if(count > 1 && count < 5) {
      return <p>{ count }&nbsp;<span>špatné</span> odpovědi</p>
    }

    return <p>{ count }&nbsp;<span>špatných</span> odpovědí</p>
  }

  useEffect(() => {
    setCorrect(result.filter(item => item.success).length);
    setFailed(result.filter(item => !item.success).length);
  }, [result]);

  return (
    <Wrapper>
      <ResultWrapper>
        {
          result.length === correct ? (
            <>
              <ResultIcon success>
                <CheckIcon />
              </ResultIcon>
              <div>
                <ResultTitle>Gratulujeme!</ResultTitle>
                <PointsInfo success>{correct*5} bodů</PointsInfo>
                <ResultMessage>
                  Z nutných <strong>{result.length*5} bodů, potřebných ke splnění</strong>, jste získal/a <strong>{correct*5} bodů</strong> a testem jste prošel/a.
                </ResultMessage>
              </div>
            </>
          ) : (
            <>
              <ResultIcon>
                <FailIcon />
              </ResultIcon>
              <div>
                <ResultTitle>Bohužel</ResultTitle>
                <PointsInfo>{correct*5} bodů</PointsInfo>
                <ResultMessage>
                  Z nutných <strong>{result.length*5} bodů, potřebných ke splnění</strong>, jste získal/a <strong>{correct*5} bodů</strong> a testem jste neprošel/a.
                </ResultMessage>
              </div>
            </>
          )
        }
      </ResultWrapper>
      {
        result.length === correct ? (
          <>
            <PointsInfoItem success>
              <CheckIcon />
              {getCorrectLabel(correct)}
            </PointsInfoItem>
            <PointsInfoItem>
              <FailIcon />
              {getFailedLabel(failed)}
            </PointsInfoItem>
          </>
        ) : (
          <>
            <PointsInfoItem>
              <FailIcon />
              {getFailedLabel(failed)}
            </PointsInfoItem>
            <PointsInfoItem success>
              <CheckIcon />
              {getCorrectLabel(correct)}
            </PointsInfoItem>
          </>
        )
        }

    </Wrapper>
  );
}

export default ResultPanel;