import styled from 'styled-components';

const Number = styled.span`
  color: #EA650D;
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  margin-right: 2.25rem;

  span {
    font-weight: 500;
    font-size: 1rem;
  }
`;

const Text = styled.div`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #343A40;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0.375rem;
  max-width: 700px;
  margin: 0 auto;
  /* align-items: center; */
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background: rgba(233, 236, 239, 0.5);
  border-radius: 2px;
  min-height: 60px;
  padding: 1rem 1.25rem;
  align-items: center;
`;

function PrizeList({data}) {
  return (
    <Wrapper>
      {
        data.map((item, index) => (
          <Item key={index}>
            <Number>
              <span>#</span>{index+1}
            </Number>
            <Text>
              {item}
            </Text>
          </Item>
        ))
      }
    </Wrapper>
  );
}

export default PrizeList;