import { useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { getVersion } from './services/testService';
import { getAll as getAllUsers } from './services/userService';
import ProtectedRoute from './components/Authentication/protectedRoute';
import NotFound from './components/NotFound';
import Login from './components/Login';
import Main from './components/Main';
import useAuth from './hooks/useAuth';
import { HOME, LOGIN } from './routes';

function App() {
  const history = useHistory();
  const { isAuthenticated } = useAuth();

  return (
    <Switch>
      {/* <Route path={'/'}>
        { isAuthenticated ? <Redirect to={HOME} /> : <Redirect to={LOGIN} />}
      </Route> */}
      <Route exact path={LOGIN} component={Login} />
      <ProtectedRoute path={HOME} roles={['ADMIN', 'USER']} component={Main} />
      <Route component={NotFound}/>
  </Switch>
  );
}

export default App;
