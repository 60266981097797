import { createGlobalStyle } from "styled-components";
import theme from '../../config/theme';
import resetCss from './index.css';
import antdCss from 'antd/dist/antd.css';

const GlobalStyle = createGlobalStyle`
  ${antdCss};
  ${resetCss};

  body {
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    line-height: 1.4;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    height: 100%;
    text-rendering: optimizeLegibility;
    font-size: 16px;
    background-color: ${theme.colors.bg_body};
  }

  // antd: modal close btn position
  .ant-modal-close {
    top: 16px;
    right: 32px;
  }
`;

export default GlobalStyle;