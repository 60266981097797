import { bool } from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  color: ${({isError}) => isError ? '#FF6158' : 'inherit'};
  font-size: 0.75rem;
  line-height: 0.875rem;
  font-weight: 500;
  margin-top: 0.375rem;
`;

function Message ({isError, children}) {
  return (
    <Wrapper isError={isError}>
      {children}
    </Wrapper>
  );
}

Message.propTypes = {
  isError: bool,
}

Message.defaultProps = {
  isError: false,
}
export default styled(Message)``;