import { useMemo } from 'react'
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { logout } from  '../services/authService'
import { getSession } from '../utils/auth';

const authHeader = () => {
  const data = getSession();

  if(data?.token) {
      return `Bearer ${data.token}`;
  }
  return {};
}

const WithAxios = ({ children }) => {
    const history = useHistory();

    useMemo(() => {
      axios.interceptors.request.use(function (config) {
        // Do something before request is sent
        config.headers['Authorization'] = authHeader();
        config.headers['Cache-Control'] = 'max-age=0, no-cache, must-revalidate, proxy-revalidate';
        return config;
      }, function (error) {
        // Do something with request error
        return Promise.reject(error);
      });

      // Add a response interceptor
      axios.interceptors.response.use(function (response) {
        return response;
      }, function (error) {
        if(error.response && error.response.status && error.response.status === 401) {
          logout(history);
          // authService.logout();
        }
        else if(error.response && error.response.status && error.response.status === 500) {
          return Promise.reject({...error, response: {...error.response, data: {error: 'Nastala interní chyba systému!'}}});
        }
        else if(error.response && error.response.status && error.response.status === 403) {
          // console.error('opravneni');
          // history.push('/opravneni-error');
          logout(history);
          return Promise.reject({...error, response: {...error.response, data: {error: 'Nemáte dostatečná oprávnění k požadované akci!'}}});
        }
        else if (error.message && error.message.includes('Network Error')) {
            console.error('Nepodařilo se spojit se serverem!');
            // history.push('/server-error');
            return Promise.reject({...error, response: {...error.response, data: {error: 'Nepodařilo se spojit se serverem!'}}});
        }
        return Promise.reject(error);
      });
    }, [history]);

    return children;
}

export default WithAxios;