import { node, string } from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #212529;
  padding-left: 1rem;
  margin-bottom: 1.875rem;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 32px;
    background-color: ${({borderColor}) => borderColor};
    border-radius: 2px;
  }
`;

function SectionTitle({children, className, borderColor}) {
  return (
    <Wrapper className={className} borderColor={borderColor}>
      {children}
    </Wrapper>
  );
}

SectionTitle.propTypes = {
  borderColor: string,
  children: node.isRequired,
  className: string,
}

SectionTitle.defaultProps = {
  borderColor: '#F47C27',
  className: '',
}

export default styled(SectionTitle)``;