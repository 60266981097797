import styled from 'styled-components';
import { string } from 'prop-types';

const Wrapper = styled.div`
  display: inline-block;
  border-radius: 4px;
  padding: 0.125rem 0.5rem;
  background-color: ${({theme, bgColor}) => bgColor ? theme.colors[bgColor] : theme.colors.primary};
  color: ${({theme}) => theme.colors.white};
`;

function Badge({text, bgColor }) {
  return (
    <Wrapper bgColor={bgColor}>
      {text}
    </Wrapper>
  );
}

Badge.propTypes = {
  text: string.isRequired,
  bgColor: string,
};

Badge.defaultProps = {
  bgColor: undefined,
};

export default Badge;