import styled from 'styled-components';
import SpinnerIcon from './spinnerIcon';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(233, 236, 239, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 9999;
`;

function PageLoader() {
  return (
    <Wrapper>
      <SpinnerIcon />
    </Wrapper>
  );
}

export default PageLoader;