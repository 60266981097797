import { DateTime } from 'luxon';

export const shortDate = date => {
  const dateValue = DateTime.fromISO(date);

  if(!dateValue.isValid) {
    return DateTime.fromSQL(date).toLocaleString();
  }
  return DateTime.fromISO(date).toLocaleString();
}

export const longDateTime = (date) => {
  const dateValue = DateTime.fromISO(date);

  if(!dateValue.isValid) {
    return DateTime.fromSQL(date).toFormat('D / T');
  }
  return DateTime.fromISO(date).toFormat('D / T')
}