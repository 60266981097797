import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as ClockSvg } from '../../assets/icons/icon-clock.svg';
import { ReactComponent as PlusSvq } from '../../assets/icons/icon-plus.svg';
import { ReactComponent as QuestionMarkSvq } from '../../assets/icons/icon-questionmark.svg';
import SectionTitle from '../SectionTitle';
import Item from './item';
import Button from '../Button';

const ClockIcon = styled(ClockSvg)`
  width: 50px;
  height: auto;
  fill: #F47C27;
`;

const PlusIcon = styled(PlusSvq)`
  width: 50px;
  height: auto;
  fill: #F47C27;
`;

const QuestionMarkIcon = styled(QuestionMarkSvq)`
  width: 50px;
  height: auto;
  fill: #F47C27;
`;

const Content = styled.div`
  max-width: 900px;
  margin: 0 auto;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1.5rem;
  margin: 2rem 0;
`;

const GridItem = styled.div`
  background-color: rgba(233, 236, 239, 0.5);
  border-radius: 8px;
  padding: 1rem 1.25rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  span {
    font-family: 'DM Sans', Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 2rem;
    color: #212529;
    margin-left: 1.5rem;
  }
`;

const BtnWrapper = styled.div`
    margin-top: 1.5rem;
  padding: 1.25rem;
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${Button} {
    max-width: 350px;
  }
`;

function Questions({name, points, duration, data, evaluateTest}) {
  const [testState, setTestState] = useState([]);

  const handleOptionSelect = (qId, oId) => {
    console.log('witke', qId, oId);
    const newArr = testState.map(item => {
      if(item.id === qId) {
        return {...item, selected: oId};
      }
      return item;
    })
    setTestState(newArr);
  }

  const getQuestionCountLabel = count => {
    if(count < 5 ) {
      return 'otázky';
    }
    return 'otázek';
  }

  useEffect(() => {
    setTestState(data.map(item => ({id: item.id, selected: null})));
  }, []);

  return (
    <Content>
      <SectionTitle>{name}</SectionTitle>
      <InfoGrid>
        <GridItem>
          <PlusIcon />
          <span>{points} bodů</span>
        </GridItem>
        <GridItem>
          <ClockIcon />
          <span>{duration} minut</span>
        </GridItem>
        <GridItem>
          <QuestionMarkIcon />
          <span>{data?.length} {getQuestionCountLabel(data?.length)}</span>
        </GridItem>
      </InfoGrid>

      {
        data.map(item => (
          <Item
            key={item.id}
            id={item.id}
            question={item.question}
            options={item.options}
            state={testState.find(it => it.id === item.id)}
            handleSelect={(id) => handleOptionSelect(item.id, id)}
           />
          // <div key={item.id}>
          //   <p>{item.question}</p>
          //   <div>
          //     {item.options.map(q => (
          //       <div key={q.id}>
          //         <p>{q.id}) {q.value}</p>
          //       </div>
          //     ))}
          //   </div>
          // </div>
        ))
      }
      <BtnWrapper>
        <Button disabled={testState.filter(item => item.selected === null).length > 0} onClick={() => evaluateTest(testState)}>Vyhodnotit</Button>
      </BtnWrapper>
    </Content>
  );
}

export default Questions;