import Item from 'antd/lib/list/Item';
import { string } from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as FolderSvg } from '../../assets/icons/icon-folder.svg';

const FolderIcon = styled(FolderSvg)`
  width: 64px;
  height: auto;
  fill: ${({fillColor}) => fillColor};
  margin-bottom: 1rem;
  transition: fill ease 500ms;
`;

const Title = styled.div`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #343A40;
  margin-bottom: 0.375rem;
  transition: color ease 500ms;
`;

const DescriptionText = styled.div`
  font-size: 0.75rem;
  color: #6C757D;
  transition: color ease 500ms;
`;

const Wrapper = styled.div`
  width: 160px;
  height: 160px;
  background-color: ${({bgColor}) => bgColor};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0.25rem;
  cursor: pointer;
  transition: background-color ease 500ms;

  &:hover {
    background-color: ${({color}) => color};

    ${FolderIcon} {
      fill: ${({theme}) => theme.colors.white};
    }

    ${Title}, ${DescriptionText} {
      color: ${({theme}) => theme.colors.white};
    }
  }
`;

function FolderItem({className, title, description, color, bgColor}) {
  return (
    <Wrapper className={className} color={color} bgColor={bgColor}>
      <FolderIcon fillColor={color} />
      <Title>{title}</Title>
      <DescriptionText>{description}</DescriptionText>
    </Wrapper>
  );
}

FolderItem.propTypes = {
  color: string.isRequired,
  bgColor: string.isRequired,
  title: string.isRequired,
  description: string.isRequired,
  className: string,
};

FolderItem.defaultProps = {
  className: '',
};

export default styled(FolderItem)``;