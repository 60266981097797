import {
  isAuthenticated,
  getSession
} from '../utils/auth';

function useAuth() {
  return {
    isAuthenticated: isAuthenticated(),
    ...getSession(),
  };
}
export default useAuth;