import { useEffect, useState } from 'react';
import { func, string } from 'prop-types';
import styled from 'styled-components';
import { Modal } from 'antd';
import { getDetail, update } from '../../services/userRequestService';
import CloseIcon from '../Modal/closeIcon';
import SectionTitle from '../SectionTitle';
import { longDateTime } from '../../utils/dataFormat';
import RequestDataDescription from './requestDataDescription';
import { getRequestStr } from "../../utils/requestType";
import Button from '../Button';
import PageLoader from '../Loaders/pageLoader';

const Wrapper = styled.div`
  position: relative;
  min-height: 350px;
`;
const Label = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #ADB5BD;
  margin-bottom: 0.25rem;
`;

const Value = styled.div`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #343A40;
`;

const Info = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.25rem;
  row-gap: 1.875rem;
  margin-top: 1.875rem;
  margin-bottom: 3.125rem;
`;


const BtnWrapper = styled.div`
  text-align: center;

  ${Button} {
    max-width: 350px;
    margin-bottom: 1rem;
    background: #29F2AD;

    &:hover {
      background-color: #29F2AD;
    }
  }
`;

function RequestDetail({id, onClose, invokeReload}) {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  // TODO submit error

  const handleClick = () => {
    setSubmitting(true);
    setSubmitError(null);

    update(id, {state: 'COMPLETED'})
      .then( () => {
          invokeReload();
      })
      .catch(error => {
        setSubmitError(error);
      })
      .then(() => setSubmitting(false));
  }

  useEffect(() => {
    getDetail(id)
      .then( data => {
        setData(data);
      })
      .catch(error => {
        setError(error);
      })
      .then(() => setLoading(false));
  }, []);

  return (
    <Modal
      bodyStyle={{padding: '1.875rem 3.125rem'}}
      visible={true}
      onCancel={onClose}
      footer={null}
      width={800}
      closeIcon={<CloseIcon />}
      >
      <SectionTitle>{data.state  === 'NEW' ? 'Nový požadavek' : 'Uzavřený požadavek'}</SectionTitle>
      <Wrapper>
        {
          isLoading ?
            <PageLoader />
            :
            <>
              <Info>
                <div>
                  <Label>Uživatel</Label>
                  <Value>{ data.user_id ? `${data.name} ${data.surname}` : '-'}</Value>
                </div>
                <div>
                  <Label>Vytvořeno</Label>
                  <Value>{longDateTime(data.created_at)}</Value>
                </div>
                <div>
                  <Label>Typ požadavku</Label>
                  <Value>{getRequestStr(data.type)}</Value>
                </div>
                <RequestDataDescription
                  type={data.type}
                  data={data.data} />
              </Info>

              {
                data.status !== 'COMPLETED' && (
                  <BtnWrapper>
                  <Button onClick={handleClick} disabled={submitting}>
                    Vyřešeno
                  </Button>
                  </BtnWrapper>
                )
              }
            </>
        }
      </Wrapper>
    </Modal>
  );
}

RequestDetail.propTypes = {
  id: string.isRequired,
  onClose: func.isRequired,
  invokeReload: func.isRequired,
}

export default RequestDetail;