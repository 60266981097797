import Badge from '../components/Badge';

export const getRequestStr = type => {
  switch(type) {
    case 'NEW_ACCOUNT':
      return 'Registrace';
    case 'PASSWORD_RECOVERY':
      return 'Obnova hesla';
    case 'USER_INFO_CHANGE':
      return 'Změna osob. údajů';
    case 'OTHER':
      return 'Jiné';
    default:
      return '---';
  };
}


export const getRequestBadge = type => {
  switch(type) {
    case 'NEW_ACCOUNT':
      return <Badge text={getRequestStr(type)} bgColor="success" />;
    case 'PASSWORD_RECOVERY':
      return <Badge text={getRequestStr(type)} bgColor="error" />;
    case 'USER_INFO_CHANGE':
      return <Badge text={getRequestStr(type)} bgColor="error" />;
    case 'OTHER':
      return <Badge text={getRequestStr(type)} bgColor="error" />;
    default:
      return '---';
  };
}

export const getRequestDescriptionValue = (type, data) => {
  switch(type) {
    case 'NEW_ACCOUNT':
      const tempData = JSON.parse(data);
      return (
        <div>
          <p>Jméno: <strong>{tempData.name}</strong></p>
          <p>Příjmení: <strong>{tempData.surname}</strong></p>
          <p>E-mail: <strong>{tempData.email}</strong></p>
          <p>Telefon: <strong>{tempData.phone}</strong></p>
        </div>
      );
    case 'PASSWORD_RECOVERY':
      return (
        <p>Nové heslo pro uživatele s e-mailem: <strong>{data}</strong></p>
      );
    case 'USER_INFO_CHANGE':
    case 'OTHER':
      return (
        <div>{data}</div>
      );
    default:
      return '---';
  };
}