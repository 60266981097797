const theme = {
  colors: {
    bg_body: '#f6f8fb',
    primary: '#000',
    primary_medium: '#f47c27',
    text_primary: '#212529',
    white: '#fff',
    success: '#29f2ad',
    error: '#ff6158',
    grey: '#adb5bd',
  },
};

export default theme;