import styled from "styled-components";
import { longDateTime } from "../../utils/dataFormat";
import { getRequestStateBadge } from "../../utils/requestState";
import { getRequestStr } from "../../utils/requestType";
import { ActionItem, ActionsWrapper } from "../DataTable/common";
import { ReactComponent as ArrowRightSvg } from '../../assets/icons/icon-arrow-right2.svg';

const ArrowIcon = styled(ArrowRightSvg)`
  width: 26px;
  height: auto;
  fill: #ADB5BD;

  &:hover {
    fill: #F47C27;
  }
`;

const RequestName = styled.span`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #343A40;
`;

const DateTime = styled.span`
  color: #ADB5BD;
`;

const getColumns = ({setSelectedRequest}) => [
  {
    id: 'id',
    Header: 'Č. požadavku',
    accessor: 'id',
  },
  {
    id: 'type',
    Header: 'Typ',
    accessor: 'type',
    Cell: ({value}) => <RequestName>{getRequestStr(value)}</RequestName>,
  },
  {
    id: 'user',
    Header: 'Uživatel',
    accessor: 'user_id',
    Cell: ({value, row: {original: {name, surname}}}) => value ? `${name} ${surname}` : '-',
  },
  {
    id: 'state',
    Header: 'Stav',
    accessor: 'state',
    Cell: ({value}) => getRequestStateBadge(value),
  },
  {
    id: 'created_at',
    Header: 'Vytvořeno',
    accessor: 'created_at',
    Cell: ({value}) => value ? <DateTime>{longDateTime(value)}</DateTime> : '-'
  },
  {
    accessor: 'none',
    Cell: ({value, row: {original: {id }}}) => (
      <ActionsWrapper>
        <ActionItem onClick={() => setSelectedRequest(id)}>
          <ArrowIcon />
        </ActionItem>
        {/* <ActionItem>zobrazit</ActionItem>
        <ActionItem>upravit</ActionItem>
        <ActionItem>odstranit</ActionItem> */}
      </ActionsWrapper>
    )
  }
];

export default getColumns;