import { useState } from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { logout } from '../../../services/authService';
import useAuth from '../../../hooks/useAuth';
import { ReactComponent as LogOutSvg } from '../../../assets/icons/icon-logout.svg';
import { ReactComponent as HelpSvg } from '../../../assets/icons/icon-help.svg';
import SupportModal from '../../SupportModal';

const iconStyle = css`
  width: 20px;
  height: 20px;
  fill: #6C757D;
  margin-right: 1.5rem;
`;

const HelpIcon = styled(HelpSvg)`
  ${iconStyle};
`;

const LogOutIcon = styled(LogOutSvg)`
  ${iconStyle};
`;

const Wrapper = styled.div`
  position: relative;
  padding-top: 2.25rem;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 10%;
    height: 2px;
    width: 80%;
    background-color: #343A40;
  }
`;

const Menu = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const Item = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #6C757D;

  &:hover {
    cursor: pointer;
    color: #F8F9FA;

    ${HelpIcon}, ${LogOutIcon} {
      fill: #F8F9FA;
    }
  }

  &:not(:last-child) {
    margin-bottom: 2.25rem;
  }
`;

function SecondaryMenu() {
  const [isSupportModalOpen, setSupportModalOpen] = useState(false);
  const {user: { role }} = useAuth();
  const history = useHistory();

  const onLogout = () => {
    logout(history);
  }

  return (
    <>
      <SupportModal isOpen={isSupportModalOpen} onClose={() => setSupportModalOpen(false)} />
      <Wrapper>
        <Menu>
          {
            role === 'USER' && (
              <Item onClick={() => setSupportModalOpen(true)}>
                <HelpIcon />
                <span>Podpora</span>
              </Item>
            )
          }
          <Item onClick={onLogout}>
            <LogOutIcon />
            <span>Odhlásit se</span>
          </Item>
        </Menu>
      </Wrapper>
    </>
  );
}

export default SecondaryMenu;