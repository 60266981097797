import styled from 'styled-components';
import DefaultSpinnerIcon from '../Loaders/spinnerIcon';

const SpinnerIcon = styled(DefaultSpinnerIcon)`
  width: 50px;
  height: 50px;
  margin: 0 auto 1rem;
`;

const InfoWrapper = styled.div`

  text-align: center;
  color: ${({ theme }) => theme.colors.light_text};
  padding: 2.5rem 1rem;
  margin-top: 1rem;

`;

function Loader() {
  return (
    <InfoWrapper>
      <SpinnerIcon />
      Načítání dat...
    </InfoWrapper>
  );
}

export default Loader;