import styled from 'styled-components';
import { DateTime } from 'luxon';
import useAuth from '../../hooks/useAuth';

const Wrapper = styled.div`
  background-color: #e9ecef;
  padding: 1.5rem 3.75rem;
  margin-left: 320px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 400;
`;

const Greetings = styled.div`
  font-size: 1.125rem;
  color: #6C757D;

  span {
    font-weight: 700;
  }
`;

const DateInfo = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  color: #6C757D;
`;

const DayName = styled.span`
  display: inline-block;
  color: #ADB5BD;

  &:first-letter {
    text-transform: uppercase;
  }
`;

function TopBar() {

  const {user: { name, surname }} = useAuth();
  const date = DateTime.now().setLocale('cs');

  return (
    <Wrapper>
      <Greetings>
        Dobrý den, <span>{`${name} ${surname}`}!</span>
      </Greetings>
      <DateInfo>
        <DayName>{date.toFormat('cccc')},</DayName> <span>{date.toFormat('DDD')}</span>
      </DateInfo>
    </Wrapper>
  );
}

export default TopBar;