import axios from 'axios';
import { API_URL } from '../config';

const SERVICE_URL = 'user';

export const getAll = () => {
  return axios.get(`${API_URL}/users`)
    .then(res => res.data);
}

export const getDetail = id => {
  return axios.get(`${API_URL}/users/${id}`)
    .then(res => res.data);
}

export const getUserLogs = id => {
  return axios.get(`${API_URL}/logs/${id}`)
    .then(res => res.data);
}

export const getAllParents = () => {
  return axios.get(`${API_URL}/users/parent`)
    .then(res => res.data);
}

export const getProfile = () => {
  return axios.get(`${API_URL}/${SERVICE_URL}/profile`)
    .then(res => res.data);
}

export const create = data => {
  return axios.post(`${API_URL}/users`, {...data})
    .then(res => res.data);
}

// export const updateUser = (id, data) => {
//   return axios.put(`${API_URL}/${SERVICE_URL}/${id}`, {...data})
//     .then(res => res.data);

// }

// export const deleteUser = id => {
//   return axios.delete(`${API_URL}/${SERVICE_URL}/${id}`)
//     .then(res => res.data);
// }

// export const changePassword = ({password, newPassword, newPasswordConfirmation}) => {
//   return axios.put(`${API_URL}/${SERVICE_URL}/change-password`, {
//     oldPassword: password,
//     password: newPassword,
//     password_confirmation: newPasswordConfirmation,
//   })
//     .then(res => res.data);
// }

// export const changeUserPassword = ({id, password, passwordConfirmation}) => {
//   return axios.put(`${API_URL}/${SERVICE_URL}/${id}/change-password`, {
//     password: password,
//     password_confirmation: passwordConfirmation,
//   })
//     .then(res => res.data);
// }