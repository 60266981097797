import { node, func, string, bool } from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.button`
  background: linear-gradient(91.68deg, #EA650D 0%, #F47C27 100%);
  border-radius: 8px;
  height: 50px;
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: ${({theme}) => theme.colors.white};
  font-size: 1.125rem;
  line-height: 1.5rem;
  border: 0;
  width: 100%;
  cursor: pointer;
  /* margin-bottom: 0.375rem; */
  outline: 0;

  &:hover, &:focus {
    outline: 0;
  }

  ${({disabled}) => disabled && `
    background: #DEE2E6;
    cursor: auto;
    color: #6C757D;
  `}

  ${({disabled}) => !disabled && `
    &:hover {
      background: #F47C27;
      box-shadow: inset 0px -3px 4px rgba(33, 37, 41, 0.15), inset 0px 3px 4px rgba(33, 37, 41, 0.15);
    }
  `}

  @media (min-width: 768px) {
    height: 60px;
  }
`;

function Button({ className, children, onClick, disabled}) {

 return (
  <Wrapper className={className} disabled={disabled} onClick={ !disabled && onClick}>
    {children}
  </Wrapper>
 );
}

Button.propTypes = {
  className: string,
  children: node.isRequired,
  onClick: func.isRequired,
  disabled: bool,
}

Button.defaultProps = {
  className: '',
  disabled: false,
}

export default styled(Button)``;