import { number, string, bool } from 'prop-types';
import styled from 'styled-components';
import { useHistory } from "react-router-dom";
import { DateTime } from 'luxon';
import SectionTitle from '../SectionTitle';
import imgSampleSrc from '../../assets/images/img-bg-kurz.jpg';
import { ReactComponent as ClockSvg } from '../../assets/icons/icon-clock.svg';
import { ReactComponent as PlusSvq } from '../../assets/icons/icon-plus.svg';
import { ReactComponent as ArrowSvg } from '../../assets/icons/icon-arrow-right.svg';
import Bullet from '../Bullet';
import { ReactComponent as CheckSvq } from '../../assets/icons/icon-check.svg';
import { longDateTime, shortDate } from '../../utils/dataFormat';

const CheckIcon = styled(CheckSvq)`
  width: 30px;
  height: auto;
  fill: #29F2AD;
  margin-right: 1rem;
`;

const ClockIcon = styled(ClockSvg)`
  width: 25px;
  height: auto;
  fill: #F47C27;
`;

const PlusIcon = styled(PlusSvq)`
  width: 25px;
  height: auto;
  fill: #F47C27;
`;

const ArrowIcon = styled(ArrowSvg)`
  width: 20px;
  height: auto;
  fill: #CED4DA;
`;

const ImgPanel = styled.div`
  /* height: 30%; */
  height: 230px;
  background-image: url(${imgSampleSrc});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 2px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(33, 37, 41, 0.15);
    backdrop-filter: blur(2px);
    border-radius: 2px;
  }

  ${Bullet} {
    position: absolute;
    top: 30px;
    right: 30px;
  }
`;

const Wrapper = styled.div`
  background: #FDFDFD;
  box-shadow: 2px 2px 13px rgba(33, 37, 41, 0.15);
  border-radius: 2px;
  cursor: pointer;
  position: relative;

  &:hover {
    background: rgba(233, 236, 239, 0.5);

    ${ArrowIcon} {
      fill: #ADB5BD;
    }

    ${ImgPanel} {
      &:before {
        backdrop-filter: blur(0px);
      }
    }
  }
`;

const Content = styled.div`
  padding: 1.875rem;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1.625rem;

  ${ClockIcon} {
    margin-right: 0.75rem;
  }
`;

const Text = styled.div`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #6C757D;
`;

const PrizeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1.625rem;

  ${PlusIcon} {
    margin-right: 0.75rem;
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ResultOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(33, 37, 41, 0.65);
border-radius: 2px;
z-index: 10;
`;

const OverlayText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const OverlayPoints = styled.div`
  margin-top: 45px;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  height: 100px;
  display: flex;
  margin-bottom: 1.5rem;
  background: rgba(33, 37, 41, 0.9);
  border-radius: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #F8F9FA;
`;

const PositiveText = styled.span`
  color: #29F2AD !important;
`;

const PointsLabel = styled.span`
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;
text-align: center;
`;

const PointsValue = styled.span`
  font-family: 'DM Sans';
font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 38px;
/* identical to box height */

text-align: center;

   span {
    font-size: 16px;
    font-weight: 400;
   }
`;

function Item({id, name, points, duration, execData}) {
  const history = useHistory();

  return (
    <Wrapper onClick={() => history.push(`/uzivatel/kurzy/${id}`)}>
      {
        execData?.state === 'SUCCESS' && (
          <ResultOverlay>
              <OverlayPoints>
                <PointsValue>{execData.points}<span>/{points}</span></PointsValue>
                <PointsLabel>bodů</PointsLabel>
              </OverlayPoints>
             <OverlayText>
              <CheckIcon />
              <PositiveText>Splněno {shortDate(execData.finished_at)}</PositiveText>
            </OverlayText>
          </ResultOverlay>
        )
      }
      <ImgPanel>
      </ImgPanel>
      <Content>
        <SectionTitle>{name}</SectionTitle>
        <DateWrapper>
          <ClockIcon />
          <Text>
            {`${duration} minut`}
            {/* {`${DateTime.fromSQL(startDate).toFormat('D')} - ${DateTime.fromSQL(endDate).toFormat('D')}`}
            <ColoredText active={active}>{active ? ' / končí za 3 dny' : ' / již ukončena' }</ColoredText> */}
          </Text>
        </DateWrapper>
        <PrizeWrapper>
          <PlusIcon />
          <Text>
            {`${points} bodů`}
          </Text>
        </PrizeWrapper>
        {/* <DescriptionText>{description}</DescriptionText> */}
        <ArrowWrapper>
          <ArrowIcon />
        </ArrowWrapper>
      </Content>
    </Wrapper>
  );
}

// Item.propTypes = {
//   id: number.isRequired,
//   label: string.isRequired,
//   startDate: string.isRequired,
//   endData: string.isRequired,
//   prize: string.isRequired,
//   description: string.isRequired,
//   active: bool.isRequired
// }

export default Item;