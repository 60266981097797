import styled from "styled-components";
import { Link } from 'react-router-dom';
import { longDateTime } from "../../../utils/dataFormat";
import { ActionItem, ActionsWrapper } from "../../DataTable/common";

const DateTimeStr = styled.span`
  color: #ADB5BD;
`;

const RoleStatus = styled.span`
  text-transform: capitalize;
`;

const Name = styled.span`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #212529;
`;

const getColumns = () => [
  {
    id: 'name',
    Header: 'Jméno a příjmení',
    accessor: 'name',
    Cell: ({row: {original: {name, surname}}}) => {
    return <Name>{`${name} ${surname}`}</Name>
    }
  },
  {
    id: 'email',
    Header: 'E-mail',
    accessor: 'email',
  },
  {
    id: 'career_status',
    Header: 'Pozice',
    accessor: 'career_status',
    Cell: ({ value }) => <RoleStatus>{value.toLowerCase()}</RoleStatus>
  },
  {
    id: 'parent_id',
    Header: 'Nadř. uživatel',
    accessor: 'parent_id',
    Cell: ({value, row: {original: {parent_name, parent_surname}}}) => value ? <span>{`${parent_name} ${parent_surname}`}</span> : '-'
  },
  {
    id: 'last_login_at',
    Header: 'Posl. přihlášení',
    accessor: 'last_login_at',
    Cell: ({value}) => value ? <DateTimeStr>{longDateTime(value)}</DateTimeStr> : '-'
  },
  {
    accessor: 'none',
    Cell: ({ row: {original: { id }}}) => (
      <ActionsWrapper>
        <ActionItem>upravit</ActionItem>
        <ActionItem>
          <Link to={`/uzivatel/sprava-uzivatelu/${id}`}>detail</Link>
        </ActionItem>
        <ActionItem>odstranit</ActionItem>
      </ActionsWrapper>
    )
  }
];

export default getColumns;