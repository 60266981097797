import { useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { notification, Alert } from 'antd';
import { Form, Field } from 'react-final-form';
import { create } from '../../services/userRequestService';
import { validateFormValues } from '../../utils/formValidation';
import Button from '../Button';
import { InputWrapper } from '../Forms/common';
import Label from '../Forms/label';
import Message from '../Forms/message';
// import Input from '../Forms/input';
import Select from '../Forms/select';
import TextArea from '../Forms/textarea';
import { FIELD_REQUIRED } from '../../utils/validationMessages';

const schema = yup.object().shape({
  type: yup.mixed().oneOf(['USER_INFO_CHANGE', 'OTHER'], FIELD_REQUIRED),
  message: yup.string().required(FIELD_REQUIRED),
  // TODO max length
});

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.875rem;
`;

const BtnWrapper = styled.div`
  text-align: center;
  ${Button} {
    max-width: 350px;
    margin-bottom: 1rem;
  }
`;

function RequestForm() {
  const [showError, setShowError] = useState(false);

  const onSubmit = (values) => {
    setShowError(false);

    return create(values)
      .then(data => {
        notification['success']({
          message: 'Nový požadavek',
          description:
            'Vás požadavek byl úspěšně odeslán.',
        });
      })
      .catch(error => {
        setShowError(true);
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={validateFormValues({schema})}
      initialValues={{
        type: '',
        message: '',
      }}
      render={({ handleSubmit, submitting }) => (
        <Grid>
          { showError && (
             <Alert
              message="Nový požadavek"
              description="Při odeslání požadavku došlo k chybě."
              type="error"
              showIcon
            />
          )}

          <Field
              name="type"
              render={({ input, meta }) => (
                <InputWrapper>
                  <Label>Typ požadavku:</Label>
                  <Select {...input} {...meta} autoComplete="off" disabled={submitting}>
                    <option value="" disabled>Vyberte hodnotu ...</option>
                    <option value="USER_INFO_CHANGE">Změna osobních údajů</option>
                    <option value="OTHER">Jiné</option>
                  </Select>
                  {meta.touched && meta.error && <Message isError>{meta.error}</Message>}
                </InputWrapper>
              )}
            />

            <Field
              name="message"
              render={({ input, meta }) => (
                <InputWrapper>
                  <Label>Popis požadavku:</Label>
                  <TextArea {...input} {...meta} disabled={submitting} />
                  {meta.touched && meta.error && <Message isError>{meta.error}</Message>}
                </InputWrapper>
              )}
            />

            <BtnWrapper>
              <Button onClick={handleSubmit}>Odeslat</Button>
            </BtnWrapper>
        </Grid>
      )}
    />
  );
}

export default RequestForm;