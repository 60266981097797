import styled from 'styled-components';
import NumberFormat from 'react-number-format';
// import { useMemo } from 'react';
// import { getAll } from '../../../services/userService';

// import DataTable from '../../DataTable';
// import getColumns from './columns';

const DATA = [
  {
    id: 1,
    name: 'Jméno',
    surname: 'Příjmení',
    points: 1556,
  },
  {
    id: 2,
    name: 'Jméno',
    surname: 'Příjmení',
    points: 1556,
  },
  {
    id: 3,
    name: 'Jméno',
    surname: 'Příjmení',
    points: 1556,
  },
  {
    id: 4,
    name: 'Jméno',
    surname: 'Příjmení',
    points: 1556,
  },
];

const Grid = styled.div`
  display: grid;
  grid-template-columns: 160px 1fr 160px;
  margin-bottom: 1.25rem;
`;

const Order = styled.span`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #343A40;
`;

const Cell = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #DEE2E6;
  color: #6C757D;
  font-weight: ${({highlight}) => highlight ? 700 : 400};
`;

const Row = styled.div`
  display: contents;

  ${Cell}:first-child {
    padding-left: 3.125rem;
  }

  ${Cell}:last-child {
    padding-right: 3.125rem;
  }

  &:hover {
    ${Cell} {
      background: rgba(233, 236, 239, 0.5);
    }
  }
`;

const HeaderRow = styled(Row)`
  &:hover {
    ${Cell} {
      background: #DEE2E6;
    }
  }

  ${Cell} {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-family: 'DM Sans', Helvetica, Arial, sans-serif;
    font-weight: 500;
    border-bottom: 0;
    background-color: #DEE2E6;
    color: #212529;
  }
`;

const SectionCell = styled.div`
  grid-column: span 3;
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-align: center;
  color: #343A40;
  padding: 1.5rem;
  border-bottom: 1px solid #DEE2E6;
`;

function RankList() {

  // const loadData = () => {
  //   setLoading(true);
  //   setError(false);
  //   getAll()
  //   .then( data => {
  //     setData(data);
  //   })
  //   .catch(error => {
  //     setError(false);
  //   })
  //   .then(() => setLoading(false));
  // }
  // useEffect(() => {
  //   loadData();
  // }, []);


  return (
    <Grid>
      <HeaderRow>
          <Cell>
            Pořadí
          </Cell>
          <Cell>
            Tým
          </Cell>
          <Cell>
            Bodů
          </Cell>
      </HeaderRow>
      {
        DATA.map(item => (
          <Row>
            <Cell>
              <Order>
                {`#${item.id}`}
              </Order>
            </Cell>
            <Cell>{`${item.name} ${item.surname}`}</Cell>
            <Cell>
              <NumberFormat value={item.points} displayType="text" thousandSeparator={' '} suffix=" bodů" />
            </Cell>
          </Row>
        ))
      }
      <SectionCell>
        Vaše pozice v soutěži
      </SectionCell>
      <Row>
          <Cell>
            <Order>
              {`#112`}
            </Order>
          </Cell>
          <Cell highlight>Jméno a příjmení</Cell>
          <Cell highlight>
            <NumberFormat value={1556} displayType="text" thousandSeparator={' '} suffix=" bodů" />
          </Cell>
        </Row>
    </Grid>
  );
}

export default RankList;