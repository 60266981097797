export const HOME = '/';
export const DASHBOARD = '/uzivatel';
export const LOGIN = '/prihlaseni';
export const ADMIN_USERS = '/uzivatel/sprava-uzivatelu';
export const ADMIN_USER_NEW = '/uzivatel/sprava-uzivatelu/novy';
export const ADMIN_USER_DETAIL = '/uzivatel/sprava-uzivatelu/:id';
export const ADMIN_REQUESTS = '/uzivatel/sprava-pozadavku';
export const ADMIN_EXAMS = '/uzivatel/sprava-kurzu';
export const USER_PROFILE = '/uzivatel/profil';
export const DOCUMENTS = '/uzivatel/dokumenty';
export const COMPETITIONS = '/uzivatel/souteze';
export const COMPETITION_DETAIL = '/uzivatel/souteze/:id';
export const EXAMS = '/uzivatel/kurzy';
export const EXAM_DETAIL = '/uzivatel/kurzy/:id';