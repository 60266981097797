import { number, string, bool } from 'prop-types';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import SectionTitle from '../SectionTitle';
import imgSampleSrc from '../../assets/images/img-sample-01.jpg';
import { ReactComponent as ClockSvg } from '../../assets/icons/icon-clock.svg';
import { ReactComponent as TrophySvg } from '../../assets/icons/icon-trophy-outlined.svg';
import { ReactComponent as ArrowSvg } from '../../assets/icons/icon-arrow-right.svg';
import Bullet from '../Bullet';

const ClockIcon = styled(ClockSvg)`
  width: 25px;
  height: auto;
  fill: #F47C27;
`;

const TrophyIcon = styled(TrophySvg)`
  width: 25px;
  height: auto;
  fill: #F47C27;
`;

const ArrowIcon = styled(ArrowSvg)`
  width: 20px;
  height: auto;
  fill: #CED4DA;
`;

const ImgPanel = styled.div`
  /* height: 30%; */
  height: 230px;
  background-image: url(${imgSampleSrc});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 2px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(33, 37, 41, 0.65);
    backdrop-filter: blur(3px);
    border-radius: 2px;
  }

  ${Bullet} {
    position: absolute;
    top: 30px;
    right: 30px;
  }
`;

const Wrapper = styled.div`
  background: #FDFDFD;
  box-shadow: 2px 2px 13px rgba(33, 37, 41, 0.15);
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    background: rgba(233, 236, 239, 0.5);

    ${ArrowIcon} {
      fill: #ADB5BD;
    }

    ${ImgPanel} {
      &:before {
        backdrop-filter: blur(0px);
      }
    }
  }
`;

const Content = styled.div`
  padding: 1.875rem;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1.625rem;

  ${ClockIcon} {
    margin-right: 0.75rem;
  }
`;

const Text = styled.div`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #6C757D;
`;

const ColoredText = styled.span`
  color: ${({active}) => active ? '#29F2AD' : '#FF6158'};
`;

const DescriptionText = styled.div`
  color: #6C757D;
  margin-bottom: 3.125rem;
`;

const PrizeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1.625rem;

  ${TrophyIcon} {
    margin-right: 0.75rem;
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;


function Item({id, label, startDate, endDate, prize, description, active}) {
  console.log('witke', endDate, startDate)
  return (
    <Wrapper>
      <ImgPanel>
        <Bullet bgColor={active ? '#29F2AD' : '#FF6158'} />
      </ImgPanel>
      <Content>
        <SectionTitle>{label}</SectionTitle>
        <DateWrapper>
          <ClockIcon />
          <Text>
            {`${DateTime.fromSQL(startDate).toFormat('D')} - ${DateTime.fromSQL(endDate).toFormat('D')}`}
            <ColoredText active={active}>{active ? ' / končí za 3 dny' : ' / již ukončena' }</ColoredText>
          </Text>
        </DateWrapper>
        <PrizeWrapper>
          <TrophyIcon />
          <Text>{prize}</Text>
        </PrizeWrapper>
        <DescriptionText>{description}</DescriptionText>
        <ArrowWrapper>
          <ArrowIcon />
        </ArrowWrapper>
      </Content>
    </Wrapper>
  );
}

Item.propTypes = {
  id: number.isRequired,
  label: string.isRequired,
  startDate: string.isRequired,
  endData: string.isRequired,
  prize: string.isRequired,
  description: string.isRequired,
  active: bool.isRequired
}

export default Item;