import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { EXAMS } from '../../routes';
import { getDetail, getContent, evaluateExam } from '../../services/examService';
import BreadcrumbNav from '../BreadcrumbNav';
import Container from '../common/container';
import Panel from '../common/panel';
import TopPanel from '../TopPanel';
import bgSrc from '../../assets/images/img-bg-kurz-lg.jpg';
import SectionTitle from '../SectionTitle';
import { ReactComponent as ClockSvg } from '../../assets/icons/icon-clock.svg';
import { ReactComponent as PlusSvq } from '../../assets/icons/icon-plus.svg';
import { ReactComponent as CheckSvq } from '../../assets/icons/icon-check.svg';
import { ReactComponent as CloseSvq } from '../../assets/icons/icon-close.svg';
import Button from '../Button';
import Questions from './questions';
import Result from './result';
import Documents from './documents';
import { shortDate } from '../../utils/dataFormat';

const ClockIcon = styled(ClockSvg)`
  width: 50px;
  height: auto;
  fill: #F47C27;
`;

const PlusIcon = styled(PlusSvq)`
  width: 50px;
  height: auto;
  fill: #F47C27;
`;

const CloseIcon = styled(CloseSvq)`
  width: 30px;
  height: auto;
  fill: #FF6158;
`;

const CheckIcon = styled(CheckSvq)`
  width: 30px;
  height: auto;
  fill: #29F2AD;
`;

const Content = styled.div`
  max-width: 900px;
  margin: 0 auto;
`;

const Img = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 2rem;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1.5rem;
  margin: 2rem 0;
`;

const GridItem = styled.div`
  background-color: rgba(233, 236, 239, 0.5);
  border-radius: 8px;
  padding: 1rem 1.25rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  span {
    font-family: 'DM Sans', Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 2rem;
    color: #212529;
    margin-left: 1rem;
  }
`;

const NegativeText = styled.span`
  color: #FF6158 !important;
`;

const PositiveText = styled.span`
  color: #29F2AD !important;
`;

const BtnWrapper = styled.div`
  background-color: rgba(233, 236, 239, 0.5);
  border-radius: 2px;
  margin-top: 1.5rem;
  padding: 1.25rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  ${Button} {
    max-width: 350px;
  }
`;

const DescriptionWrapper = styled.div`
  color: #6C757D;
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
margin: 2rem auto;
`;

// TODO: handle error
function ExamDetail() {
  const [data, setData] = useState(null);
  const [content, setContent] = useState(null);
  const [execId, setExecId] = useState(null);
  const [resultData, setResultData] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [navItems, setNavItems] = useState([{
    key: 'home',
    label: 'Kurzy',
    link: EXAMS,
  },
  {
    key: 'current',
    label: '-',
  },]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { id } = useParams();

  const handleStart = () => {
    getContent(id)
      .then(data => {
        // console.log('witke data',);
        setContent(JSON.parse(data.content));
        setInProgress(true);
        setExecId(data.execId);
        window.scrollTo(0,0);
      })
  }

  const handleEvaluateTest = (testState) => {
    evaluateExam({id, result: testState, execId})
      .then((res) => {
        setResultData(res);
        setInProgress(false);
        setShowResult(true);
        window.scrollTo(0,0);
      })
  }

  const loadData = () => {
    setLoading(false);
    setError(false);

    getDetail(id)
      .then(data => {
        setData(data);
        setNavItems([navItems[0], {
          key: 'current',
          label: `${data.name}`,
        }])
      })
      .catch(error => {
        setError(false);
      })
      .then(() => setLoading(false));
  }

  useEffect(() => {
    loadData(id);
  }, [id]);

  if(!data) {
    return null;
  }

  return (
    <>
      <TopPanel>
        <BreadcrumbNav items={navItems} />
      </TopPanel>
      <Container>
        <Panel>
          {
            inProgress && (
              <Questions
                id={data.id}
                name={data.name}
                points={data.points}
                duration={data.duration}
                data={content}
                evaluateTest={handleEvaluateTest} />
            )
          }

          {
            showResult && (
              <Result
                id={data.id}
                name={data.name}
                points={data.points}
                duration={data.duration}
                content={content}
                result={resultData} />
            )
          }

          { !inProgress && !showResult && (
            <Content>
              <Img src={bgSrc} alt="Foto" />
              <SectionTitle>{data.name}</SectionTitle>
              <InfoGrid>
                <GridItem>
                  <PlusIcon />
                  { data.execData && data.execData.state === 'SUCCESS' ? (
                    <span>{data.execData.points} / {data.points} bodů</span>
                  ) : (
                    <span>Max. {data.points} bodů</span>
                  )}

                </GridItem>
                <GridItem>
                  <ClockIcon />
                  <span>{data.duration} minut</span>
                </GridItem>
                <GridItem>
                { data.execData && data.execData.state === 'SUCCESS' ? (
                    <>
                      <CheckIcon />
                      <PositiveText>Splněno {shortDate(data.execData.finished_at)}</PositiveText>
                    </>
                  ) : (
                    <>
                      <CloseIcon />
                      <NegativeText>Ke splnění</NegativeText>
                    </>
                )}

                </GridItem>
              </InfoGrid>
              <DescriptionWrapper>{parse(data.description)}</DescriptionWrapper>
              <Documents id={id} data={JSON.parse(data.documents)} />
              <BtnWrapper>
                <Button onClick={handleStart} disabled={data.execData && data.execData.state === 'SUCCESS'}>Spustit test</Button>
              </BtnWrapper>
            </Content>
          )}

        </Panel>
      </Container>
    </>
  );
}

export default ExamDetail;