import axios from 'axios';
import { API_URL } from '../config';

const SERVICE_URL = 'exam';

export const getAll = () => {
  return axios.get(`${API_URL}/exams`)
    .then(res => res.data);
}

export const getDetail = id => {
  return axios.get(`${API_URL}/${SERVICE_URL}/${id}`)
    .then(res => res.data);
}

export const getResults = id => {
  return axios.get(`${API_URL}/${SERVICE_URL}/${id}/results`)
    .then(res => res.data);
}

export const getDocument = (id, docId) => {
  return axios({
    url: `${API_URL}/${SERVICE_URL}/${id}/doc?id=${docId}`,
    method: 'GET',
    responseType: 'blob',
  }).then(res => res.data);
}

export const getContent = id => {
  return axios.get(`${API_URL}/${SERVICE_URL}/${id}/content`)
    .then(res => res.data);
}

// export const getUserLogs = id => {
//   return axios.get(`${API_URL}/logs/${id}`)
//     .then(res => res.data);
// }

// export const getAllParents = () => {
//   return axios.get(`${API_URL}/users/parent`)
//     .then(res => res.data);
// }

// export const getProfile = () => {
//   return axios.get(`${API_URL}/${SERVICE_URL}/profile`)
//     .then(res => res.data);
// }

export const evaluateExam = data => {
  return axios.post(`${API_URL}/exams`, {...data})
    .then(res => res.data);
}