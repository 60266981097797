import styled from 'styled-components';
import Container from "../common/container";

const ContentWrapper = styled.div`
  padding: 1.875rem 0;
`;

function Dashboard() {
  return (
    <Container>
      <ContentWrapper>
        Dashboard content ...
      </ContentWrapper>
    </Container>
  );
}

export default Dashboard;