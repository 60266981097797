import ColoredLabel from '../components/ColoredLabel';

export const getRequestStateStr = state => {
  switch(state) {
    case 'NEW':
      return 'Otevřeno';
    case 'IN_PROGRESS':
      return 'V řešení';
    case 'COMPLETED':
        return 'Uzavřeno';
    default:
      return '---';
  };
}


// export const getRequestStateBadge = state => {
//   switch(state) {
//     case 'NEW':
//       return <Badge text={getRequestStateStr(state)} bgColor="success" />;
//     case 'IN_PROGRESS':
//       return <Badge text={getRequestStateStr(state)} bgColor="primary_medium" />;
//     case 'COMPLETED':
//       return <Badge text={getRequestStateStr(state)} bgColor="grey" />;
//     default:
//       return '---';
//   };
// }

export const getRequestStateBadge = state => {
  switch(state) {
    case 'NEW':
      return <ColoredLabel text={getRequestStateStr(state)} bgColor="success" />;
    case 'IN_PROGRESS':
      return <ColoredLabel text={getRequestStateStr(state)} bgColor="primary_medium" />;
    case 'COMPLETED':
      return <ColoredLabel text={getRequestStateStr(state)} bgColor="error" />;
    default:
      return '---';
  };
}