import { useMemo } from 'react';
// import { getAll } from '../../../services/userService';

import DataTable from '../../DataTable';
import getColumns from './columns';

const DATA = [
  {
    id: 1,
    name: 'Test filename',
    path: 'Investice / Fondy',
    created_at: '2021-09-21 09:09:51',
    size: 150,
    owner_id: 1,
    owner_name: 'Ondřej',
    owner_surname: 'Witke'
  },
  {
    id: 2,
    name: 'Test filename',
    path: 'Investice / Fondy',
    created_at: '2021-09-21 09:09:51',
    size: 150,
    owner_id: 1,
    owner_name: 'Ondřej',
    owner_surname: 'Witke'
  },
  {
    id: 3,
    name: 'Test filename',
    path: 'Investice / Fondy',
    created_at: '2021-09-21 09:09:51',
    size: 150,
    owner_id: 1,
    owner_name: 'Ondřej',
    owner_surname: 'Witke'
  }
];

function DocumentsGrid() {
  // const [data, setData] = useState([]);
  // const [isLoading, setLoading] = useState(true);
  // const [error, setError] = useState(false);

  const columns = useMemo(() => getColumns(), []);

  // const loadData = () => {
  //   setLoading(true);
  //   setError(false);
  //   getAll()
  //   .then( data => {
  //     setData(data);
  //   })
  //   .catch(error => {
  //     setError(false);
  //   })
  //   .then(() => setLoading(false));
  // }
  // useEffect(() => {
  //   loadData();
  // }, []);


  return (
    <DataTable
      columns={columns}
      data={DATA}
      loading={false}
      error={null}
    />
  );
}

export default DocumentsGrid;