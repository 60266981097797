import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import { LOGIN } from '../../routes';
import useAuth from '../../hooks/useAuth';

function ProtectedRoute ({
  component: Component,
  roles,
  ...rest
}) {
  const { isAuthenticated, user } = useAuth();

  // TODO: redirect to "not allowed" page

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated && roles.includes(user.role) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={LOGIN}
          />
        )
      }
    />
  );
}
export default ProtectedRoute;