import axios from 'axios';
import { clearSession, updateUserData } from '../utils/auth';
import { API_URL } from '../config';
import { LOGIN } from '../routes';

const SERVICE_URL = 'auth';

export const login = (user, pwd) => {
  return axios.post(`${API_URL}/${SERVICE_URL}/login`, {email: user, password: pwd})
    .then(res => res.data);
  }

export const sendRegistrationRequest = ({name, surname, email, phone}) => {
  return axios.post(`${API_URL}/${SERVICE_URL}/registration`, {name, surname, email, phone })
    .then(res => res.data);
  }

export const sendPasswordRequest = email => {
  return axios.post(`${API_URL}/${SERVICE_URL}/password`, {email})
    .then(res => res.data);
  }

export const checkTkn = history => {
  return axios.get(`${API_URL}/${SERVICE_URL}/check-session`)
    .then(res => res.data)
    .then(data => updateUserData(data))
    .catch(() => logout(history));
}

export const logout = (history) => {

  // TODO: logout user on API !!!


  // return axios.get(`${API_URL}/${SERVICE_URL}/logout`)
  //   .then(res => {
  //     localStorage.removeItem(USER_TOKEN_KEY);
  //     history.push(LOGIN);
  //   })
  //   .catch(() => logout(history));

    clearSession();
    history.push(LOGIN);

}