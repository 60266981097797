import { useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Form, Field } from 'react-final-form';
import Button from '../Button';
import { InputWrapper } from '../Forms/common';
import Label from '../Forms/label';
import Input from '../Forms/input';
import Message from '../Forms/message';
import { sendPasswordRequest } from '../../services/authService';
import { validateFormValues } from '../../utils/formValidation';
import ActionResult from '../ActionResult';
import { EMAIL_FORMAT, FIELD_REQUIRED } from '../../utils/validationMessages';
import PageLoader from '../Loaders/pageLoader';

const schema = yup.object().shape({
  email: yup.string().required(FIELD_REQUIRED).email(EMAIL_FORMAT),
});

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.875rem;

  ${Input} {
    height: 50px;
  }

  @media (min-width: 768px) {
    ${Input} {
      height: 60px;
    }
  }
`;

const BottomPanel = styled.div`
  margin-bottom: 0.125rem;

  ${Button} {
    margin-bottom: 0.375rem;
  }

  ${ActionResult} {
    text-align: center;
    margin-bottom: 1.875rem;
  }
`;


function PasswordForm() {
  const [showResult, setShowResult] = useState(false);
  const [showError, setShowError] = useState(false);

  const onSubmit = ({email}) => {
    setShowError(false);
    setShowResult(false)

    return sendPasswordRequest(email)
      .then(data => {
        setShowResult(true);
      })
      .catch(error => {
        setShowError(true);
      })
  }

  return (
    <div>
      <Form
          onSubmit={onSubmit}
          validate={validateFormValues({schema})}
          initialValues={{
            email: '',
          }}
          render={({ handleSubmit, submitting }) => (
            <Grid>
              { submitting && <PageLoader /> }
              <Field
                  name="email"
                  render={({ input, meta }) => (
                    <InputWrapper>
                      <Label>E-mail:</Label>
                      <Input type="text" {...input} {...meta} disabled={submitting} />
                      {meta.touched && meta.error && <Message isError>{meta.error}</Message>}
                    </InputWrapper>
                  )}
                />
                <BottomPanel>
                  { showResult && <ActionResult>Žádost byla úspěšně odeslána</ActionResult> }
                  { showError && <ActionResult isError>Při odeslání žádosti došlo k chybě</ActionResult> }
                  <Button onClick={handleSubmit} disabled={submitting}>
                    Odeslat žádost
                  </Button>
                </BottomPanel>
            </Grid>
          )}
        />
    </div>
  );
}

export default PasswordForm;