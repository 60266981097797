import styled from 'styled-components';

export default styled.textarea`
  display: block;
  background: #F8F9FA;
  border: 1px solid #CED4DA;
  box-sizing: border-box;
  border-radius: 8px;
  height: 40px;
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  line-height: 1.375rem;
  color: #495057;
  font-weight: 500;
  outline: none;
  width: 100%;
  padding: 0.5rem;
  min-height: 120px;

  ${({ touched, invalid, theme}) =>
    touched && invalid &&
    `
      border-color: ${theme.colors.error};
    `}

  :focus,
  :active {
    outline: 0;
    border: 1px solid #CED4DA;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline:none;
  }
`;
