import styled from 'styled-components';
import { ReactComponent as CloseSvg } from '../../assets/icons/icon-close.svg';

const Icon = styled(CloseSvg)`
  fill: #FF6158;
`;

function CloseIcon() {
  return (
    <Icon />
  );
}

export default CloseIcon;