import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Modal from 'react-modal';
import GlobalStyle from './components/GlobalStyle';
import { ThemeProvider } from 'styled-components';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './config/theme';
import WithAxios from './hocs/withAxios';
// import 'antd/dist/antd.css';

Modal.setAppElement('#root');

ReactDOM.render(
    <>
      <GlobalStyle />
      <BrowserRouter>
        <WithAxios>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </WithAxios>
      </BrowserRouter>
    </> ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
