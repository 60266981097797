import styled from 'styled-components';
import { string } from 'prop-types';

const Text = styled.span`
  color: ${({theme, bgColor}) => bgColor ? theme.colors[bgColor] : theme.colors.primary};
`;

function ColoredLabel({text, bgColor }) {
  return (
    <Text bgColor={bgColor}>
      {text}
    </Text>
  );
}

ColoredLabel.propTypes = {
  text: string.isRequired,
  bgColor: string,
};

ColoredLabel.defaultProps = {
  bgColor: undefined,
};

export default ColoredLabel;