import styled from 'styled-components';
import { useHistory } from "react-router-dom";
import SectionTitle from '../SectionTitle';
import Item from './item';
import Button from '../Button';
import ResultItem from './resultItem';
import ResultPanel from './resultPanel';

const Content = styled.div`
  max-width: 900px;
  margin: 0 auto;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1.5rem;
  margin: 2rem 0;
`;

const GridItem = styled.div`
  background-color: rgba(233, 236, 239, 0.5);
  border-radius: 8px;
  padding: 1rem 1.25rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  span {
    font-family: 'DM Sans', Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 2rem;
    color: #212529;
    margin-left: 1.5rem;
  }
`;

const BtnWrapper = styled.div`
    margin-top: 1.5rem;
  padding: 1.25rem;
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${Button} {
    max-width: 350px;
  }
`;

function Result({name, points, duration, content, result}) {
  const history = useHistory();

  return (
    <Content>
      <SectionTitle>{name}</SectionTitle>
      <ResultPanel result={result} />

      {
        content.map((item, idx) => (
          <ResultItem
            key={item.id}
            id={item.id}
            question={item.question}
            options={item.options}
            result={result[idx]}
           />
        ))
      }
      <BtnWrapper>
        <Button onClick={() => history.push(`/uzivatel/kurzy`)}>Zpět na výpis kurzů</Button>
      </BtnWrapper>
    </Content>
  );
}

export default Result;