import React, { useEffect, useState } from 'react';
import { useTable, useSortBy, usePagination, useExpanded } from 'react-table';
import { arrayOf, shape, bool, number, string, func, oneOfType } from 'prop-types';
import styled, { css } from 'styled-components';
// import { useTranslation } from ‘react-i18next’;
// import { Text1Css, Text2Css, CaptionCss } from ‘../Headings’;
// import Pagination from ‘./Pagination’;
// import {
//   ErrorIcon,
//   SortDownIcon,
//   SortUpIcon,
//   SpinnerAnimatedIcon,
// } from ‘../Icons’;
import { ReactComponent as ArrowSvg } from '../../assets/icons/icon-arrow-down2.svg';
import DefaultSpinnerIcon from '../Loaders/spinnerIcon';

const SpinnerIcon = styled(DefaultSpinnerIcon)`
  width: 50px;
  height: 50px;
  margin: 0 auto 1rem;
`;
const Wrapper = styled.div`
  overflow: inherit;
  ${({ tableStyle }) =>
    !tableStyle.removeWrapperMargin &&
    `
      margin-bottom: 2rem;
    `};
`;
const sortIconStyle = css`
  display: inline-block;
  margin-left: 0.25rem;
  width: 18px;
  height: 18px;
  fill: #ADB5BD;
`;
// const Spinner = styled(SpinnerAnimatedIcon)`
//   display: block;
//   margin: 0 auto 1rem;
// `;

const ArrowIconDown = styled(ArrowSvg)`
  ${sortIconStyle};
`;

const ArrowIconUp = styled(ArrowIconDown)`
  transform: rotate(180deg);
`;

const InfoWrapper = styled.div`

  text-align: center;
  color: ${({ theme }) => theme.colors.light_text};
  padding: 3.375rem 1rem 1rem 1rem;
  margin-top: 1rem;

`;
const PaginationWrapper = styled.div`
  margin-top: 1rem;
`;
const Table = styled.table`

  color: ${({ theme }) => theme.colors.medium_text};
  width: 100%;
`;
const Th = styled.th`
  ${({ customAlignment }) =>
    customAlignment &&
    `
    text-align: ${customAlignment};
    `};
  ${({ customHeaderWidth }) =>
    customHeaderWidth &&
    `
      width: ${customHeaderWidth}px;
      `};
  ${({ customHeaderMinWidth }) =>
    customHeaderMinWidth &&
    `
      min-width: ${customHeaderMinWidth}px;
      `};
`;
const Td = styled.td`
  ${({ customAlignment }) =>
    customAlignment &&
    `
    text-align: ${customAlignment};
    `};
  ${({ $cssMixin = '' }) => $cssMixin};
`;
const ExpandedRow = styled.tr``;
const ExpandedContent = styled.td`
  padding: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark_bg};
`;
const TableHeader = styled.thead`
  color: ${({ theme }) => theme.colors.dark_text};

  ${({ tableStyle, theme }) =>
    !tableStyle.transparentHeader &&
    `
    background-color: #DEE2E6;
    `};

  tr ${Th}:first-child {
    border-top-left-radius: 2px;
  }

  tr ${Th}:last-child {
    border-top-right-radius: 2px;
  }

  tr ${Th} {
    padding: 1.375rem 1.875rem;
    font-family: 'DM Sans', Helvetica, Arial, sans-serif;
    font-weight: 700;
    /* font-size: 1.125rem; */
    font-size: 1rem;
    color: #212529;

    /* ${({ tableStyle, theme }) =>
      !tableStyle.transparentHeader &&
      `
      border-bottom: 1px solid ${theme.colors.dark_bg};
    `}; */
  }
  /* tr ${Th}:first-child {
    padding-left: 0.5rem;
  }
  tr ${Th}:last-child {
    padding-right: 0.5rem;
  } */
`;
const TableBody = styled.tbody`
  background-color: #FDFDFD;
  color: #6C757D;

  tr:hover {
    /* background-color: ${({ theme }) => theme.colors.indigo_alpha_003}; */
    background-color: rgba(233, 236, 239, 0.5);
  }

  ${ExpandedRow}:hover {
    background-color: ${({ theme }) => theme.colors.transparent};
  }
  tr ${Td} {
    padding: 1.125rem 1.875rem;

    border-bottom: 1px solid #DEE2E6;
    vertical-align: top;
  }
  tr ${Td}:first-child {
    /* padding-left: 0.5rem; */
    border-left: 1px solid #DEE2E6;
  }
  tr ${Td}:last-child {
    /* padding-right: 0.5rem; */
    border-right: 1px solid #DEE2E6;
  }
  ${({ tableStyle }) =>
    !tableStyle.showLastRowBorder &&
    `
      tr:last-child td {
        border-bottom: none;
      }
    `};
  a,
  span[role=‘button’] {
    color: ${({ theme }) => theme.colors.dark_blue};
  }
  a:hover,
  span[role=‘button’]:hover {
    text-decoration: underline;
  }
  span[role=‘button’]:hover {
    cursor: pointer;
  }
`;
/**
 * We are using custom props (except react-table built-in props):
 * Header: customHeaderAlignment, customHeaderWidth
 * Cell: customAlignment
 * detailView: custom component which is used to display detail view of the row (row props are passed down to the component)
 *
 * Custom table style prop:
 *
 *  tableStyle: {
 *    transparentHeader: bool,
 *    removeWrapperMargin: bool,
 *    showLastRowBorder: bool,
 *  }
 *
 */
const DEFAULT_TABLE_STYLE = {
  transparentHeader: false,
  removeWrapperMargin: false,
  showLastRowBorder: true,
};
const defaultPropsGetter = () => ({});
function DataTable({
  columns,
  data,
  pageCount,
  currentPageIndex,
  pageSize,
  manualMode,
  onTableStateChange,
  onPageChange,
  disableSorting,
  initialSortBy,
  loading,
  error,
  detailView,
  tableStyle,
  getCellProps = defaultPropsGetter,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    state: { sortBy, pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize,
        sortBy: initialSortBy ? [initialSortBy] : [],
      },
      pageCount,
      disableMultiSort: true,
      manualSortBy: manualMode,
      disableSortBy: disableSorting,
      manualPagination: manualMode,
      useControlledState: (state) => {
        const newTableState = manualMode
          ? { ...state, pageIndex: currentPageIndex }
          : { ...state };
        return React.useMemo(
          () => ({
            ...newTableState,
          }),
          // TODO: fix this, and fix the next error that occurs
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [state, currentPageIndex],
        );
      },
    },
    useSortBy,
    useExpanded,
    usePagination,
  );
  const CustomDetailView = detailView;
  const [customTableStyle] = useState({
    ...DEFAULT_TABLE_STYLE,
    ...tableStyle,
  });
  // dispatch table state in in “manual” table mode
  useEffect(() => {
    if (manualMode) {
      onTableStateChange({ sortBy });
    }
  }, [onTableStateChange, manualMode, sortBy]);

  return (
    <Wrapper tableStyle={customTableStyle}>
      <Table {...getTableProps()}>
        <TableHeader tableStyle={customTableStyle}>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // eslint-disable-next-line
                <Th
                  {...{
                    customAlignment: column.customHeaderAlignment,
                    customHeaderWidth: column.customHeaderWidth,
                    customHeaderMinWidth: column.customHeaderMinWidth,
                    ...column.getHeaderProps(column.getSortByToggleProps()),
                  }}
                >
                  {column.render('Header')}
                  {column.isSorted &&
                    (column.isSortedDesc ? <ArrowIconDown /> : <ArrowIconUp />)}
                </Th>
              ))}
            </tr>
          ))}
        </TableHeader>
        {!error && !loading && data.length > 0 && (
          <TableBody {...getTableBodyProps()} tableStyle={customTableStyle}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.id}>
                  {/* eslint-disable-next-line */}
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      // eslint-disable-next-line
                      <Td
                        {...cell.getCellProps([
                          { customAlignment: cell.column.customAlignment },
                          getCellProps(cell),
                        ])}
                      >
                        {cell.render('Cell')}
                      </Td>
                    ))}
                  </tr>
                  {row.isExpanded && detailView && (
                    <ExpandedRow key={row.id}>
                      <ExpandedContent colSpan={columns.length}>
                        <CustomDetailView {...row.original} />
                      </ExpandedContent>
                    </ExpandedRow>
                  )}
                </React.Fragment>
              );
            })}
          </TableBody>
        )}
      </Table>
      {!error && !loading && data.length === 0 && (
        <InfoWrapper>Žádná data k zobrazení!</InfoWrapper>
      )}
      {loading && (
        <InfoWrapper>
          <SpinnerIcon />
          Načítání dat...
        </InfoWrapper>
      )}
      {/* {error && !loading && (
        <InfoWrapper>
          <ErrorIcon />
          {typeof error === 'string’ ? error : t(‘table.loading_error’)}
        </InfoWrapper>
      )} */}
      {!error && (canPreviousPage || canNextPage) && (
        <PaginationWrapper>
          {/* <Pagination
            {...{
              loading,
              canPreviousPage,
              canNextPage,
              onPreviousPage: () =>
                manualMode ? onPageChange(pageIndex - 1) : previousPage(),
              onNextPage: () =>
                manualMode ? onPageChange(pageIndex + 1) : nextPage(),
            }}
          /> */}
        </PaginationWrapper>
      )}
    </Wrapper>
  );
}
DataTable.propTypes = {
  columns: arrayOf(shape({})).isRequired,
  data: arrayOf(shape({})).isRequired,
  getCellProps: func,
  onTableStateChange: func,
  onPageChange: func,
  manualMode: bool,
  loading: bool,
  currentPageIndex: number,
  pageCount: number,
  pageSize: number,
  disableSorting: bool,
  initialSortBy: shape({
    id: string.isRequired,
    desc: bool.isRequired,
  }),
  error: oneOfType([string, bool]),
  detailView: func,
  tableStyle: shape({
    transparentHeader: bool,
    removeWrapperMargin: bool,
    showLastRowBorder: bool,
  }),
};

DataTable.defaultProps = {
  manualMode: false,
  loading: false,
  getCellProps: undefined,
  onTableStateChange: undefined,
  onPageChange: undefined,
  currentPageIndex: 0,
  pageCount: -1,
  pageSize: 10,
  disableSorting: false,
  initialSortBy: undefined,
  detailView: undefined,
  error: undefined,
  tableStyle: {
    transparentHeader: false,
    removeWrapperMargin: false,
    showLastRowBorder: true,
  },
};
export default DataTable;