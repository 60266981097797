import { oneOfType, string, shape } from 'prop-types';
import styled from 'styled-components';
import { getRequestDescriptionValue } from '../../utils/requestType';

const Wrapper = styled.div`
  grid-column: span 2;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #ADB5BD;
  margin-bottom: 0.25rem;
`;

const Value = styled.div`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #343A40;

  strong {
    font-weight: 500;
  }
`;

function RequestDataDescription({type, data}) {

  return (
    <Wrapper>
      <Label>Doplňující informace</Label>
      <Value>
        {getRequestDescriptionValue(type, data)}
      </Value>
    </Wrapper>
  );
}

RequestDataDescription.propTypes = {
  type: string.isRequired,
  data: oneOfType([string, shape]).isRequired,
}

export default RequestDataDescription;