import styled from "styled-components";
import { longDateTime } from "../../../utils/dataFormat";
import { ActionItem, ActionsWrapper } from "../../DataTable/common";
// import { ReactComponent as ArrowRightSvg } from '../../assets/icons/icon-arrow-right2.svg';

const RequestName = styled.span`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #343A40;
`;

const DateTime = styled.span`
  color: #ADB5BD;
`;

const getColumns = ({showExamResults, showExamPreview}) => [
  {
    id: 'name',
    Header: 'Název',
    accessor: 'name',
  },
  {
    id: 'points',
    Header: 'Počet bodů',
    accessor: 'points',
    // Cell: ({value}) => <RequestName>{getRequestStr(value)}</RequestName>,
  },
  {
    id: 'duration',
    Header: 'Délka (min)',
    accessor: 'duration',
    // Cell: ({value, row: {original: {name, surname}}}) => value ? `${name} ${surname}` : '-',
  },
  {
    accessor: 'none',
    Cell: ({value, row: {original: {id }, original}}) => (
      <ActionsWrapper>
        <ActionItem onClick={() => showExamResults(original)}>
          výsledky
        </ActionItem>
        <ActionItem onClick={() => showExamPreview(id)}>
          náhled
        </ActionItem>
      </ActionsWrapper>
    )
  }
];

export default getColumns;