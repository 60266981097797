import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { ReactComponent as HomeSvg } from '../../../assets/icons/icon-home.svg';
import { ReactComponent as LearningSvg } from '../../../assets/icons/icon-learning.svg';
import { ReactComponent as TasksSvg } from '../../../assets/icons/icon-tasks.svg';
import { ReactComponent as DownloadSvg } from '../../../assets/icons/icon-download.svg';
import { ReactComponent as TrophySvg } from '../../../assets/icons/icon-trophy.svg';
import { ReactComponent as UsersSvg } from '../../../assets/icons/icon-users.svg';
import { ADMIN_REQUESTS, ADMIN_USERS, DASHBOARD, DOCUMENTS, COMPETITIONS, EXAMS, ADMIN_EXAMS } from '../../../routes';

const iconStyle = css`
  width: 20px;
  height: 20px;
  fill: #E9ECEF;
  margin-right: 1.5rem;
`;

const HomeIcon = styled(HomeSvg)`
  ${iconStyle};
`;

const LearningIcon = styled(LearningSvg)`
  ${iconStyle};
`;

const TasksIcon = styled(TasksSvg)`
  ${iconStyle};
`;

const DownloadIcon = styled(DownloadSvg)`
  ${iconStyle};
`;

const TrophyIcon = styled(TrophySvg)`
  ${iconStyle};
`;

const UsersIcon = styled(UsersSvg)`
  ${iconStyle};
`;

const Wrapper = styled.div`
  margin-bottom: 2.25rem;
`;

const Menu = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const Item = styled.li`

  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #E9ECEF;

  a {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #E9ECEF;
    text-decoration: none;
  }

  a.active {
    color: #F47C27;
    ${HomeIcon}, ${LearningIcon}, ${TasksIcon}, ${DownloadIcon}, ${TrophyIcon},
    ${UsersIcon} {
      fill: #F47C27;
    }
  }

  &:hover {
    cursor: pointer;
    color: #F47C27;

    a {
      color: #F47C27;
    }

    ${HomeIcon}, ${LearningIcon}, ${TasksIcon}, ${DownloadIcon}, ${TrophyIcon},
    ${UsersIcon} {
      fill: #F47C27;
    }
  }

  &:not(:last-child) {
    margin-bottom: 2.25rem;
  }
`;

function PrimaryMenu() {
  const {user: { role }} = useAuth();

  return (
    <Wrapper>
      <Menu>
        <Item>
          <NavLink exact to={DASHBOARD} activeClassName="active">
            <HomeIcon />
            <span>Domů</span>
          </NavLink>
        </Item>
        {
          role === 'ADMIN' && (
            <>
              <Item>
                <NavLink to={ADMIN_EXAMS} activeClassName="active">
                  <LearningIcon />
                  <span>Správa kurzů</span>
                </NavLink>
              </Item>
              <Item>
                <NavLink exact to={ADMIN_USERS} activeClassName="active">
                  <UsersIcon />
                  <span>Správa uživatelů</span>
                </NavLink>
              </Item>
              <Item>
                <NavLink exact to={ADMIN_REQUESTS} activeClassName="active">
                  <TasksIcon />
                  <span>Správa požadavků</span>
                </NavLink>
              </Item>
            </>
          )
        }

        { role === 'USER' && (
          <>
            <Item>
              <NavLink to={EXAMS} activeClassName="active">
                <LearningIcon />
                <span>Kurzy</span>
              </NavLink>
            </Item>
            {/* <Item>
              <TasksIcon />
              <span>Úkoly</span>
            </Item> */}
            <Item>
              <NavLink to={DOCUMENTS} activeClassName="active">
                <DownloadIcon />
                <span>Dokumenty</span>
              </NavLink>
            </Item>
            <Item>
              <NavLink to={COMPETITIONS} activeClassName="active">
                <TrophyIcon />
                <span>Soutěže</span>
              </NavLink>
            </Item>
          </>
        )}

      </Menu>
    </Wrapper>
  );
}

export default PrimaryMenu;