import styled from "styled-components";

const Label = styled.span`
  display: block;
  margin-bottom: 0.125rem;
  font-family: 'DM Sans';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 28px;
`;

const Value = styled.span`
  display: block;
  font-family: 'DM Sans';
font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 47px;
`;

const Item = styled.div`
  border-right: 1px solid #F8F9FA;
  padding: 0 1.5rem;

  &:last-child {
    border-right: 1px solid transparent;
  }
`;

const Wrapper = styled.div`
  background-color: #F47C27;
  /* color: ${({theme}) => theme.colors.white}; */
  color: #F8F9FA;
  /* padding: 1rem 1.5rem; */
  padding: 1rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 1.5rem;
`;

function InfoPanel ({totalPoints, gainedPoints, finishedCount, remainingCount}) {

  const getLabel = count => {
    if(count === 1) {
      return 'kurz';
    }

    if(count > 1 && count < 5) {
      return 'kurzy';
    }

    return 'kurzů';
  }
  return (
    <Wrapper>
      <Item>
        <Label>Bodů celkem:</Label>
        <Value>{totalPoints || '-'}</Value>
      </Item>
      <Item>
        <Label>Získáno bodů:</Label>
        <Value>{gainedPoints || '-'}</Value>
      </Item>
      <Item>
        <Label>Dokončeno:</Label>
        <Value>{finishedCount || '-'}&nbsp;{getLabel(finishedCount)}</Value>
      </Item>
      <Item>
        <Label>Zbývá:</Label>
        <Value>{remainingCount || '-'}&nbsp;{getLabel(remainingCount)}</Value>
      </Item>
    </Wrapper>
  );
}

export default InfoPanel;