import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getAll } from '../../services/examService';
import Container from '../common/container';
import TopPanel from '../TopPanel';
import PanelContent from './panelContent';
import Item from './item';
import InfoPanel from './infoPanel';
import PageLoader from '../Loaders/pageLoader';

// const Subtitle = styled.div`
//   font-family: 'DM Sans', Helvetica, Arial, sans-serif;
//   font-weight: 700;
//   font-size: 1.125rem;
//   line-height: 1.5rem;
//   color: #343A40;
//   margin-bottom: 1.25rem;
//   margin-left: 3rem;
// `;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* grid-template-columns: repeat(3, 1fr); */
  column-gap: 1.25rem;
  row-gap: 1.25rem;
  margin-bottom: 3.125rem;
`;

function Exams() {
  const [data, setData] = useState([]);
  const [panelData, setPanelData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);

  const loadData = () => {
    setLoading(true);
    setError(undefined);
    getAll()
      .then( data => {
        setData(data);
        setPanelData({
          totalPoints: data.map(item => item.points).reduce((sum, a) => sum + a),
          gainedPoints: data.filter(item => item.execData?.state === 'SUCCESS').map(item => item.points).reduce((sum, a) => sum + a),
          finishedCount: data.filter(item => item.execData?.state === 'SUCCESS').length,
          remainingCount: data.filter(item => !item.execData || item.execData?.state !== 'SUCCESS').length,
        })
      })
      .catch(error => {
        setError(error);
      })
      .then(() => setLoading(false));
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <TopPanel>
        <PanelContent />
      </TopPanel>
      <Container>
        {isLoading && <PageLoader />}
        <InfoPanel {...panelData} />
        {/* <Subtitle>Ke splnění:</Subtitle> */}
        <List>
          {
            data.map(item => (
              <Item
                id={item.id}
                name={item.name}
                points={item.points}
                duration={item.duration}
                execData={item.execData}
              />
            ))
          }
        </List>
        {/* <Subtitle>Splněno:</Subtitle> */}
      </Container>
    </>
  );
}

export default Exams;