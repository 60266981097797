import styled from 'styled-components';
import { bool, node, string } from 'prop-types';

const Wrapper = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({theme, isError}) => isError ? theme.colors.error : theme.colors.success};
`;

function ActionResult({isError, className, children}) {
  return (
    <Wrapper className={className} isError={isError}>
      {children}
    </Wrapper>
  );
}

ActionResult.propTypes = {
  isError: bool,
  children: node.isRequired,
  className: string,
};

ActionResult.defaultProps = {
  isError: false,
  className: '',
};

export default styled(ActionResult)``;