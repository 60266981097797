import styled from 'styled-components';
import logoSrc from '../../assets/images/logo-gfs-light.png';
import PrimaryMenu from './PrimaryMenu';
import SecondaryMenu from './SecondaryMenu';
import UserAvatar from './userAvatar';

const Wrapper = styled.div`
  background-color: #343434;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 320px;
  padding: 1.875rem 2.5rem;
`;

const LogoWrapper = styled.div`
  text-align: center;
  margin-bottom: 4.375rem;
`;

function Sidebar() {
  return (
    <Wrapper>
      <LogoWrapper>
        <img src={logoSrc} alt="Logo - GFS" width="180" height="45" />
      </LogoWrapper>
      <UserAvatar />
      <PrimaryMenu />
      <SecondaryMenu />
    </Wrapper>
  );
}

export default Sidebar;