import styled from 'styled-components';
import { ReactComponent as SpinnerSvg } from '../../assets/icons/icon-spinner.svg';

const SpinnerIcon = styled(SpinnerSvg)`
  width: 100px;
  height: 100px;
  margin: auto;
`;

export default SpinnerIcon;
