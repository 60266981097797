import styled from "styled-components";
import { longDateTime } from "../../../utils/dataFormat";
import { ActionItem, ActionsWrapper } from "../../DataTable/common";
import { ReactComponent as DownloadSvg } from '../../../assets/icons/icon-download2.svg';

const DownloadIcon = styled(DownloadSvg)`
  width: 20px;
  height: 20px;
  fill: #ADB5BD;
  cursor: pointer;

  &:hover {
    fill: #EA650D;
  }
`;

const DateTimeStr = styled.span`
  color: #ADB5BD;
`;

const Name = styled.span`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #343A40;
`;

const getColumns = () => [
  {
    id: 'name',
    Header: 'Název dokumentu',
    accessor: 'name',
    Cell: ({value}) => {
    return <Name>{value}</Name>
    }
  },
  {
    id: 'path',
    Header: 'Cesta',
    accessor: 'path',
  },
  {
    id: 'created_at',
    Header: 'Datum nahrání',
    accessor: 'created_at',
    Cell: ({value}) => value ? <DateTimeStr>{longDateTime(value)}</DateTimeStr> : '-'
  },
  {
    id: 'size',
    Header: 'Velikost',
    accessor: 'size',
    Cell: ({value}) => `${value} kB`
  },
  {
    id: 'owner_id',
    Header: 'Nahrál',
    accessor: 'owner_id',
    Cell: ({value, row: {original: {owner_name, owner_surname}}}) => value ? <span>{`${owner_name} ${owner_surname}`}</span> : '-'
  },
  {
    accessor: 'none',
    Cell: () => (
      <ActionsWrapper>
        <DownloadIcon />
      </ActionsWrapper>
    )
  }
];

export default getColumns;