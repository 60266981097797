import jwt_decode from 'jwt-decode';
import { USER_DATA_KEY } from '../config';

const toJson = (data) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return undefined;
  }
}

const isTokenValid = token => {
  try {
      jwt_decode(token);
      return true;
  } catch(e) {
      return false;
  }
}


export const getSession = () => toJson(localStorage.getItem(USER_DATA_KEY));

export const setSession = (token, user) => {
  localStorage.setItem(
    USER_DATA_KEY,
    JSON.stringify({
      token: token || undefined,
      user: user ? {
        ...user
      } : undefined,
    })
  );
}

export const clearSession = () => {
  localStorage.removeItem(USER_DATA_KEY);
}

export const isAuthenticated = () => {
  const session = getSession();
  if(!session || !session.token || !isTokenValid(session.token)) {
    return false;
  }
  // TODO check expireAt
  return true;
}

export const handleAuthResponse = data => {
  setSession(data.access_token, data.user);
}

export const updateUserData = data => {
  if(!data) {
    clearSession();
    return;
  }

  const currentData = getSession();
  setSession(currentData.token, data);
}