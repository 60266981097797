import styled from 'styled-components';
// import SectionTitle from '../SectionTitle';
import TopPanel from '../TopPanel';
import Panel from '../common/panel';
import Container from '../common/container';
// import CategoryList from './categoryList';
import PanelContent from './panelContent';
import Item from './item';
// import DocumentsGrid from './Grid';

const MOCKED_DATA_ACTIVE = [
  {
    id: 1,
    label: 'Nadpis soutěže',
    startDate: '2021-09-21 09:09:51',
    endDate: '2021-09-25 09:09:51',
    prize: 'Zájezd do New Yorku',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse nisl. Morbi imperdiet, mauris ac auctor dictum, nisl ligula egestas nulla.',
  },
  {
    id: 2,
    label: 'Nadpis soutěže',
    startDate: '2021-09-21 09:09:51',
    endDate: '2021-09-25 09:09:51',
    prize: 'Zájezd do New Yorku',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse nisl. Morbi imperdiet, mauris ac auctor dictum, nisl ligula egestas nulla.',
  },
  {
    id: 3,
    label: 'Nadpis soutěže',
    startDate: '2021-09-21 09:09:51',
    endDate: '2021-09-25 09:09:51',
    prize: 'Zájezd do New Yorku',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse nisl. Morbi imperdiet, mauris ac auctor dictum, nisl ligula egestas nulla.',
  },
];

const MOCKED_DATA_INACTIVE = [
  {
    id: 1,
    label: 'Nadpis soutěže',
    startDate: '2021-09-21 09:09:51',
    endDate: '2021-09-25 09:09:51',
    prize: 'Zájezd do New Yorku',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse nisl. Morbi imperdiet, mauris ac auctor dictum, nisl ligula egestas nulla.',
  },
  {
    id: 2,
    label: 'Nadpis soutěže',
    startDate: '2021-09-21 09:09:51',
    endDate: '2021-09-25 09:09:51',
    prize: 'Zájezd do New Yorku',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse nisl. Morbi imperdiet, mauris ac auctor dictum, nisl ligula egestas nulla.',
  },
  {
    id: 3,
    label: 'Nadpis soutěže',
    startDate: '2021-09-21 09:09:51',
    endDate: '2021-09-25 09:09:51',
    prize: 'Zájezd do New Yorku',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse nisl. Morbi imperdiet, mauris ac auctor dictum, nisl ligula egestas nulla.',
  },
  {
    id: 4,
    label: 'Nadpis soutěže',
    startDate: '2021-09-21 09:09:51',
    endDate: '2021-09-25 09:09:51',
    prize: 'Zájezd do New Yorku',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse nisl. Morbi imperdiet, mauris ac auctor dictum, nisl ligula egestas nulla.',
  },
  {
    id: 5,
    label: 'Nadpis soutěže',
    startDate: '2021-09-21 09:09:51',
    endDate: '2021-09-25 09:09:51',
    prize: 'Zájezd do New Yorku',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse nisl. Morbi imperdiet, mauris ac auctor dictum, nisl ligula egestas nulla.',
  },
  {
    id: 6,
    label: 'Nadpis soutěže',
    startDate: '2021-09-21 09:09:51',
    endDate: '2021-09-25 09:09:51',
    prize: 'Zájezd do New Yorku',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse nisl. Morbi imperdiet, mauris ac auctor dictum, nisl ligula egestas nulla.',
  },
];

const Subtitle = styled.div`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #343A40;
  margin-bottom: 1.25rem;
  margin-left: 3rem;
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1.25rem;
  row-gap: 1.25rem;
  margin-bottom: 3.125rem;
`;

function Competitions() {
  return (
    <>
    <TopPanel>
      <PanelContent />
    </TopPanel>
    <Container>
      <Subtitle>Právě probíhající</Subtitle>
      <List>
        {
          MOCKED_DATA_ACTIVE.map(item => (
            <Item
              id={item.id}
              label={item.label}
              startDate={item.startDate}
              endDate={item.endDate}
              prize={item.prize}
              description={item.description}
              active
            />
          ))
        }
      </List>
      <Subtitle>Již ukončené</Subtitle>
      <List>
        {
          MOCKED_DATA_INACTIVE.map(item => (
            <Item
              id={item.id}
              label={item.label}
              startDate={item.startDate}
              endDate={item.endDate}
              prize={item.prize}
              description={item.description}
              active={false}
            />
          ))
        }
      </List>
    </Container>
    </>
  );
}

export default Competitions;