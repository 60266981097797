import axios from 'axios';
import { API_URL } from '../config';

export const getAllNew = () => {
  return axios.get(`${API_URL}/user-requests?filter=NEW`)
    .then(res => res.data);
}

export const getAllCompleted = () => {
  return axios.get(`${API_URL}/user-requests?filter=COMPLETED`)
    .then(res => res.data);
}

export const getDetail = id => {
  return axios.get(`${API_URL}/user-request/${id}`)
    .then(res => res.data);
}

export const create = data => {
  return axios.post(`${API_URL}/user-request`, {...data})
    .then(res => res.data);
}

export const update = (id, data) => {
  return axios.put(`${API_URL}/user-request/${id}`, {...data})
    .then(res => res.data);
}