import { useEffect, useState, } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { notification, Alert } from 'antd';
import { Form, Field, FormSpy } from 'react-final-form';
import { getAllParents, create } from '../../../services/userService';
import { validateFormValues } from '../../../utils/formValidation';
import Button from '../../Button';
import { InputWrapper } from '../../Forms/common';
import Input from '../../Forms/input';
import TextArea from '../../Forms/textarea';
import Label from '../../Forms/label';
import Message from '../../Forms/message';
import Select from '../../Forms/select';
import SectionTitle from '../../SectionTitle';
import TopPanel from '../../TopPanel';
import Panel from '../../common/panel';
import Container from '../../common/container';
import { ADMIN_USERS } from '../../../routes';
import BreadcrumbNav from '../../BreadcrumbNav';
import { FIELD_REQUIRED, EMAIL_FORMAT } from '../../../utils/validationMessages';
import { CAREER_ROLE } from '../../../config';
import PageLoader from '../../Loaders/pageLoader';

const NAV_ITEMS = [
  {
    key: 'home',
    label: 'Přehled uživatelů',
    link: ADMIN_USERS,
  },
  {
    key: 'current',
    label: 'Nový uživatel',
  },
];

const schema = yup.object().shape({
  name: yup.string().required(FIELD_REQUIRED),
  surname: yup.string().required(FIELD_REQUIRED),
  email: yup.string().required(FIELD_REQUIRED).email(EMAIL_FORMAT),
  phone: yup.string().required(FIELD_REQUIRED),
  password: yup.string()
    .required(FIELD_REQUIRED)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Heslo musí obsahovat malé a velké písmeno, číslo, min. délka 8 znaků',
    ),
  passwordConfirmation: yup.string()
    .required(FIELD_REQUIRED)
    .test(
      'passwordMatch',
      'Zadaná hesla se neshodují',
      function test(value) {
        return value === this.parent.password;
      },
    ),
  careerStatus: yup.string().required(FIELD_REQUIRED).oneOf(CAREER_ROLE, 'Vyberte jednu z hodnota'),
});

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1.875rem;
  column-gap: 1.875rem;
  max-width: 800px;
`;

const AlertWrapper = styled.div`
  margin-bottom: 1.875rem;
  max-width: 800px;
`;

const TextareaWrapper = styled.div`
  grid-column: span 2;
`;

const BtnWrapper = styled.div`

`;

function NewUser() {
  const [parentUsers, setParentUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  const [showError, setShowError] = useState(false);


  const loadData = () => {
    setLoading(true);
    setLoadingError(false);
    getAllParents()
    .then( data => {
      setParentUsers(data);
    })
    .catch(error => {
      setLoadingError(false);
    })
    .then(() => setLoading(false));
  }

  useEffect(() => {
    loadData();
  }, []);

  const onSubmit = (values) => {
    setShowError(false);
    console.log('witke submit');

    return create(values)
      .then(data => {
        notification['success']({
          message: 'Nový uživatel',
          description:
            'Nový uživatel byl úspěšně vytvořen.',
        });
      })
      .catch(error => {
        setShowError(true);
      })
    }


  return (
    <>
    <TopPanel>
      <BreadcrumbNav items={NAV_ITEMS} />
      {/* <PanelContent /> */}
    </TopPanel>
    <Container>
    <Panel>
      { isLoading && <PageLoader /> }
      <SectionTitle>
        Vytvoření nového uživatele
      </SectionTitle>
       { showError && (
         <AlertWrapper>
            <Alert
              message="Nový uživatel"
              description="Při vytváření uživatele došlo k chybě."
              type="error"
              showIcon
            />
          </AlertWrapper>
        )}
      <Form
      onSubmit={onSubmit}
      validate={validateFormValues({schema})}
      initialValues={{
        name: '',
        surname: '',
        phone: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        note: '',
        careerStatus: '',
        parentUser: null,
      }}
      render={({ handleSubmit, submitting }) => (
        <>
        <Grid>
          <Field
            name="name"
            render={({ input, meta }) => (
              <InputWrapper>
                <Label>Jméno:</Label>
                <Input type="text" {...input} {...meta} disabled={submitting} />
                {meta.touched && meta.error && <Message isError>{meta.error}</Message>}
              </InputWrapper>
            )}
          />

          <Field
            name="surname"
            render={({ input, meta }) => (
              <InputWrapper>
                <Label>Příjmení:</Label>
                <Input type="text" {...input} {...meta} disabled={submitting} />
                {meta.touched && meta.error && <Message isError>{meta.error}</Message>}
              </InputWrapper>
            )}
          />
          <Field
              name="email"
              render={({ input, meta }) => (
                <InputWrapper>
                  <Label>E-mail</Label>
                  <Input type="text" {...input} {...meta} disabled={submitting} />
                  {meta.touched && meta.error && <Message isError>{meta.error}</Message>}
                </InputWrapper>
              )}
            />

            <Field
              name="phone"
              render={({ input, meta }) => (
                <InputWrapper>
                  <Label>Telefon</Label>
                  <Input type="text" {...input} {...meta} disabled={submitting} />
                  {meta.touched && meta.error && <Message isError>{meta.error}</Message>}
                </InputWrapper>
              )}
            />

            <Field
              name="password"
              render={({ input, meta }) => (
                <InputWrapper>
                  <Label>Heslo</Label>
                  <Input type="password" {...input} {...meta} disabled={submitting} />
                  {meta.touched && meta.error && <Message isError>{meta.error}</Message>}
                </InputWrapper>
              )}
            />

            <Field
              name="passwordConfirmation"
              render={({ input, meta }) => (
                <InputWrapper>
                  <Label>Zopakujte heslo</Label>
                  <Input type="password" {...input} {...meta} disabled={submitting} />
                  {meta.touched && meta.error && <Message isError>{meta.error}</Message>}
                </InputWrapper>
              )}
            />

            <TextareaWrapper>
              <Field
                name="note"
                render={({ input, meta }) => (
                  <InputWrapper>
                    <Label>Poznámka:</Label>
                    <TextArea {...input} {...meta} disabled={submitting} />
                    {meta.touched && meta.error && <Message isError>{meta.error}</Message>}
                  </InputWrapper>
                )}
              />
            </TextareaWrapper>

            <Field
              name="careerStatus"
              render={({ input, meta }) => (
                <InputWrapper>
                  <Label>Role:</Label>
                  <Select {...input} {...meta} autoComplete="off" disabled={submitting}>
                    <option value="" disabled>Vyberte hodnotu ...</option>
                    {
                      CAREER_ROLE.map(item => (
                        <option key={item} value={item}>{item}</option>
                      ))
                    }
                  </Select>
                  {meta.touched && meta.error && <Message isError>{meta.error}</Message>}
                </InputWrapper>
              )}
            />

            <Field
              name="parentUser"
              render={({ input, meta }) => (
                <InputWrapper>
                  <Label>Nadřazený uživatel:</Label>
                  <Select {...input} {...meta} autoComplete="off" disabled={submitting}>
                    <option value="">Vyberte hodnotu ...</option>
                    {
                      parentUsers.map(user => (
                        <option key={user.id} value={user.id}>{`${user.surname} ${user.name}`}</option>
                      ))
                    }
                  </Select>
                  {meta.touched && meta.error && <Message isError>{meta.error}</Message>}
                </InputWrapper>
              )}
            />

            <BtnWrapper>
              <Button onClick={handleSubmit}>Odeslat</Button>
            </BtnWrapper>
        </Grid>
        <FormSpy subscription={{errors: true}}>
          {({errors}) => (
            <pre>
              {JSON.stringify(errors, null, 2)}
            </pre>
          )}
        </FormSpy>
        </>
      )}
    />
    </Panel>
    </Container>
    </>
  );
}

export default NewUser;