import styled from "styled-components";
import { bool, func } from 'prop-types';
import { Modal } from 'antd';
import RequestForm from "./requestForm";
import CloseIcon from '../Modal/closeIcon';
import SectionTitle from '../SectionTitle';

function SupportModal({isOpen, onClose}) {
  return(
    <Modal
      bodyStyle={{padding: '1.875rem 3.125rem'}}
      visible={isOpen}
      onCancel={onClose}
      footer={null}
      width={800}
      closeIcon={<CloseIcon />}
      >
      <SectionTitle>Nový požadavek</SectionTitle>
      <RequestForm />
    </Modal>
  );
}

SupportModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
}

export default SupportModal;