import { arrayOf, shape } from 'prop-types';
import styled from 'styled-components';
import SectionTitle from '../SectionTitle';
import FolderItem from './folderItem';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 340px));
  column-gap: 3.125rem;
  margin-bottom: 3.125rem;
`;

const Item = styled.div`
  ${SectionTitle} {
    margin-bottom: 1.25rem;
  }
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 160px);
  grid-auto-rows: 1fr;
  column-gap: 1.25rem;
`;

function CategoryList({items}) {
  return (
    <Wrapper>
      {items.map(item => (
        <Item key={item.key}>
          <SectionTitle borderColor={item.color}>
            {item.label}
          </SectionTitle>
          <List>
            {
              item.items.map(category => (
                <FolderItem key={category.key}
                  color={item.color}
                  bgColor={item.bgColor}
                  title={category.label}
                  description={`${category.count} souborů`}
                 />
              ))
            }
          </List>
        </Item>
      ))}
    </Wrapper>
  );
}

CategoryList.propTypes = {
  items: arrayOf(shape({})).isRequired,
}

export default CategoryList;