import { func } from 'prop-types';
import styled from 'styled-components';
import Search from '../Search';
import { ReactComponent as PlusSvg } from '../../assets/icons/icon-plus.svg';

const PlusIcon = styled(PlusSvg)`
  width: 18px;
  height: auto;
  fill: #ADB5BD;
  margin-right: 0.625rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.span`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #6C757D;
`;

// const ActionsWrapper = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
// `;

// const ActionItem = styled.div`
//   display: inline-flex;
//   flex-direction: row;
//   justify-content: flex-start;
//   cursor: pointer;
//   align-items: center;
// `;

function PanelContent({
    onAdd,
    onSearch
  }) {
  return (
    <Wrapper>
      <Label>Správa požadavků</Label>
      <Search onSearch={onSearch} />
    </Wrapper>
  );
}

PanelContent.propTypes = {
  onAdd: func.isRequired,
  onSearch: func.isRequired,
}

export default PanelContent;