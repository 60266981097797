import { string } from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #E9ECEF;
  padding: 0 3.75rem;
  margin-bottom: 3.125rem;
`;

const Content = styled.div`
  border-top: 1px solid #DEE2E6;
  padding: ${({contentPadding}) => contentPadding};
`;
function TopPanel({children, contentPadding}) {
  return (
    <Wrapper>
      <Content contentPadding={contentPadding}>
        {children}
      </Content>
    </Wrapper>
  );
}

TopPanel.propTypes = {
  contentPadding: string,
}

TopPanel.defaultProps = {
  contentPadding: '1.5rem 0',
}

export default TopPanel;