import { string, shape, arrayOf } from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Item from './item';
import React from 'react';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const Divider = styled.span`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  line-height: 1.375rem;
  font-weight: 500;
  color: #ADB5BD;
  padding: 0 0.25rem;
`;

function BreadcrumbNav({items}) {

  return (
    <Wrapper>
      {
        items.map((item, index) => (
          <React.Fragment key={item.key}>
            <Item>
              {
                item.link ? (
                  <Link to={item.link}>{item.label}</Link>
                ) : (
                  <span>{item.label}</span>
                )
              }
            </Item>
            {
              index < items.length-1 && (
                <Divider>/</Divider>
              )
            }
          </React.Fragment>
        ))
      }
    </Wrapper>
  );
}

BreadcrumbNav.propTypes = {
  items: arrayOf(
    shape({
      key: string,
      label: string,
      link: string,
    })
  ).isRequired,
}

export default BreadcrumbNav;