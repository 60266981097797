import styled from 'styled-components';
import { ReactComponent as CloseSvg } from '../../assets/icons/icon-close.svg';
import { ReactComponent as CheckSvg } from '../../assets/icons/icon-check.svg';

const FailIcon = styled(CloseSvg)`
  width: 16px;
  height: auto;
  fill: #FF6158;
  margin-right: 1.5rem;
`;

const CheckIcon = styled(CheckSvg)`
  width: 20px;
  height: auto;
  fill: #29F2AD;
  margin-right: 1.5rem;
`;

const Label = styled.span`
  display: block;
  color: #F47C27;
  margin-left: 2rem;
  margin-bottom: 0.25rem;
`;

const Wrapper = styled.div`
  border: 2px solid #F47C27;
  border-radius: 2px;
  padding: 2rem;
  margin-bottom: 1.5rem;

  p {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #212529;
  }
`;

const Circle = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid #CED4DA;
  border-radius: 50px;
  margin-right: 1.5rem;
  position: relative;

  ${({selected}) => selected && `
    border: 2px solid #F8F9FA;

    &:after{
      content: '';
      width: 12px;
      height: 12px;
      background-color: #F8F9FA;
      border-radius: 50px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
    }
  `};
`;

const Option = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: ${({selected}) => selected ? ' #343434' : 'rgba(233, 236, 239, 0.5)'} ;
  border-radius: 2px;
  padding: 1rem 2rem;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${({selected}) => selected ? '#F8F9FA' : '#6C757D'};
  margin-bottom: 0.375rem;

  &:last-child {
    margin-bottom: 0;
  }

  /* &:hover {
    background-color: #343434;
    color: #F8F9FA;

    ${Circle} {
      border: 2px solid #F8F9FA;
    }
  } */
  ${({correct}) => correct && `
    border: 2px solid #29F2AD;
  `}

  ${({selected, success}) => selected && `
    color: ${success ? '#29F2AD' : '#FF6158'};
    border: 2px solid ${success ? '#29F2AD' : '#FF6158'};
  `}
`;

function ResultItem({id, question, options, result }) {

  const getIcon = (selected, success) => {
    if(selected && !success) {
      return <FailIcon />;
    }

    if(selected && success) {
      return <CheckIcon />;
    }

    return <Circle />;
  }

  return (
    <>
      <Label>{`Otázka ${id}`}</Label>
      <Wrapper>
        <p>{question}</p>
        {
          options.map(op => (
            <Option
              key={op.id}
              selected={op.id === result.selected}
              correct={op.id === result.correct}
              success={result.success}>
              {getIcon(op.id === result.selected, result.success)}
               <span>{op.id}) {op.value}</span>
            </Option>
          ))
        }
      </Wrapper>
    </>
  );
}

export default ResultItem;