import { useEffect, useState } from "react";
import styled from "styled-components";
import { bool, func } from 'prop-types';
import { Modal } from 'antd';
import CloseIcon from '../../Modal/closeIcon';
import SectionTitle from '../../SectionTitle';
import { getContent } from "../../../services/examService";
import Loader from "../../Loaders/componentLoader";

const Info = styled.div`
  font-size: 1.25rem;
`;

const DetailWrapper = styled.div`
  margin-top: 1rem;
`;

const QuestionDetail = styled.div`
  background-color: rgba(233, 236, 239, 0.5);
  border-radius: 2px;
  padding: 0.5rem 1rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const QuestionLabel = styled.div`
  font-weight: bold ;
  font-size: 1rem;
`;

const Option = styled.span`
  display: block;
  margin: 0.25rem 0;
  color: ${({isCorrect}) => isCorrect ? '#29F2AD' : 'inherit'};
`;

// TODO handle error
function DetailModal({isOpen, selectedItem, onClose}) {

  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [results, setResults] = useState([]);

  useEffect(() => {
    if(isOpen) {
      console.log('witk', selectedItem)
      getContent(selectedItem)
        .then(data => {
          setData(data);
          setResults(data.answers.split(','));
        })
        .then(() => setLoading(false));
    }
  }, [isOpen, selectedItem]);

  return(
    <Modal
      bodyStyle={{padding: '1.875rem 3.125rem'}}
      visible={isOpen}
      onCancel={onClose}
      footer={null}
      width={800}
      closeIcon={<CloseIcon />}
      destroyOnClose={true}
      afterClose={() => {
        setData(null);
        setResults([]);
      }}
      >
      <SectionTitle>Detail kurzu</SectionTitle>

      {
        isLoading && (
          <Loader />
        )
      }
      {
        data && (
          <>
            <Info>Název: <strong>{data.name}</strong></Info>
            <Info>{`${data.points} bodů, ${data.duration} min`}</Info>

            <DetailWrapper>
              {
                JSON.parse(data.content).map(item => (
                  <QuestionDetail key={item.id}>
                    <QuestionLabel>{`${item.id}. ${item.question}`}</QuestionLabel>
                    {
                      item.options.map(opt => (
                        <Option key={opt.id} isCorrect={opt.id === results[item.id-1]}>{`${opt.id}) ${opt.value}`}</Option>
                      ))
                    }
                  </QuestionDetail>
                ))
              }
            </DetailWrapper>
          </>
        )
      }

    </Modal>
  );
}

DetailModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
}

export default DetailModal;