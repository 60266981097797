import { useState, useEffect, useMemo } from 'react';
import { number } from 'prop-types';
import styled from 'styled-components';
import { getUserLogs } from '../../../services/userService';
import DataTable from '../../DataTable';
import getColumns from './columns';

const Subtitle = styled.div`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #343A40;
  margin-bottom: 1.25rem;
`;

function UserLogs({id}) {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const columns = useMemo(() => getColumns(), []);

  const loadData = () => {
    setLoading(true);
    setError(false);
    getUserLogs(id)
    .then( data => {
      setData(data);
    })
    .catch(error => {
      setError(error);
    })
    .then(() => setLoading(false));
  }
  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Subtitle>Logy uživatele</Subtitle>
      <DataTable
        columns={columns}
        data={data}
        loading={isLoading}
        error={!!error}
      />
    </>
  );
}

UserLogs.propTypes = {
  id: number.isRequired,
};

export default UserLogs;