import { useState } from 'react';
import styled from 'styled-components';
import LoginForm from './loginForm';
import RegistrationForm from './registrationForm';
import logoSrc from '../../assets/images/logo-gfs.png';

const Wrapper = styled.div`
  background-color: ${({theme}) => theme.colors.white};
  margin: 120px auto 20px;
  box-shadow: 2px 2px 13px 3px rgba(33, 37, 41, 0.15);
  border-radius: 2px;
  padding: 2.75rem 1.5rem;
  width: 90%;

  @media (min-width: 576px) {
    width: 550px;
  }

  @media (min-width: 768px) {

    padding: 3.75rem 6.25rem;
  }
`;

const LogoWrapper = styled.div`
  text-align: center;
  margin-bottom: 2.25rem;

  @media (min-width: 768px) {
    margin-bottom: 3rem;
  }
`;

function Login() {
  const [registrationMode, setRegistrationMode] = useState(false);

  return (
    <Wrapper>
      <LogoWrapper>
        <img src={logoSrc} alt="Logo GFS" width="140" height="74" />
      </LogoWrapper>
      {
        registrationMode ? (
          <RegistrationForm showLoginForm={() => setRegistrationMode(false)} />
        ) : (
          <LoginForm showRegistrationMode={() => setRegistrationMode(true)} />
        )
      }
    </Wrapper>
  );
}

export default Login;