import { number, string, bool } from 'prop-types';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import TopPanel from '../TopPanel';
import DefaultPanel from '../common/panel';
import Container from '../common/container';
import PanelContent from './panelContent';
import imgSrc from '../../assets/images/img-sample-02.jpg';
import SectionTitle from '../SectionTitle';
import PrizeList from './prizeList';
import { ReactComponent as ClockSvg } from '../../assets/icons/icon-clock.svg';
import RankList from './RankList';

const PRIZE_LIST = [
  'Toto je krátký popis výhry',
  'Toto je krátký popis výhry',
  'Toto je krátký popis výhry'
];

const ClockIcon = styled(ClockSvg)`
  width: 25px;
  height: auto;
  fill: #F47C27;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  /* margin-bottom: 1.625rem; */

  ${ClockIcon} {
    margin-right: 0.75rem;
  }
`;

const Text = styled.div`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #6C757D;
`;

const Subtitle = styled.div`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-align: center;
  color: #343A40;
  margin-bottom: 1.25rem;
`;

const ColoredText = styled.span`
  color: ${({active}) => active ? '#29F2AD' : '#FF6158'};
`;

const Panel = styled(DefaultPanel)`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
`;

const ContentWrapper = styled.div`
  max-width: 900px;
  margin: 0 auto;
`;

const ImgPanel = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 2.5rem;
`;

const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  column-gap: 1rem;
  margin-bottom: 2.5rem;

  ${SectionTitle} {
    margin-bottom: 0;
  }
`;

const Description = styled.div`
  p {
    line-height: 1.5rem;
    color: #6C757D;
  }

  p:not(:last-child) {
    margin-bottom: 1.875rem;
  }
`;

const SectionDivider = styled.div`
  width: 300px;
  height: 2px;
  background-color: #EA650D;
  margin: 2.5rem auto;
`;

function CompetitionDetail() {

  const startDate = '2021-09-21 09:09:51';
  const endDate = '2021-09-25 09:09:51';
  const active = true;

  return (
    <>
    <TopPanel>
      <PanelContent />
    </TopPanel>
    <Container>
    <Panel>
      <ContentWrapper>
        <ImgPanel src={imgSrc} alt="Foto" />
        <InfoWrapper>
          <SectionTitle>
            Toto je dlouhý název soutěže
          </SectionTitle>
          <DateWrapper>
            <ClockIcon />
            <Text>
              {`${DateTime.fromSQL(startDate).toFormat('D')} - ${DateTime.fromSQL(endDate).toFormat('D')}`}
              <ColoredText active={active}>{active ? ' / končí za 3 dny' : ' / již ukončena' }</ColoredText>
            </Text>
          </DateWrapper>
        </InfoWrapper>
        <Description>
          <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Nullam sapien sem, ornare ac, nonummy non, lobortis a enim. Fusce suscipit libero eget elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Maecenas libero. Integer tempor. Mauris tincidunt sem sed arcu. Suspendisse sagittis ultrices augue. Maecenas sollicitudin.</p>
          <p>Phasellus rhoncus. In convallis. Cras pede libero, dapibus nec, pretium sit amet, tempor quis. Nullam eget nisl. Praesent in mauris eu tortor porttitor accumsan. Proin in tellus sit amet nibh dignissim sagittis. </p>
        </Description>

        <SectionDivider />

        <Subtitle>O co se hraje?</Subtitle>
        <PrizeList data={PRIZE_LIST} />

        <SectionDivider />

        <Subtitle>TOP10 týmů v soutěži / kategorie</Subtitle>
        <RankList />

      </ContentWrapper>
    </Panel>
    </Container>
    </>
  );
}

CompetitionDetail.propTypes = {
  id: number.isRequired,
  label: string.isRequired,
  startDate: string.isRequired,
  endData: string.isRequired,
  prize: string.isRequired,
  description: string.isRequired,
  active: bool.isRequired
}

export default CompetitionDetail;