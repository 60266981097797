import styled from 'styled-components';

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ActionItem = styled.span`
  display: inline-block;
  color: #FF6158;
  cursor: pointer;

  a {
    color: #FF6158;
  }

  &:hover {
    text-decoration: underline;
  }

  &:not(:first-child) {
    margin-left: 0.5rem;
  }
`;