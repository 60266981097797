import { setIn } from 'final-form';

export const validateFormValues = ({ schema, context = {} }) => async (
  values,
) => {
  let formSchema = schema;
  if (typeof schema === 'function') {
    formSchema = schema();
  }
  return formSchema
    .validate(values, {
      abortEarly: false,
      context,
    })
    .then(() =>
      // everything ok, return an empty response
      Promise.resolve(),
    )
    .catch((err) => {
      // console.log(err);
      // build an object that resembles the form state, with
      // error messages as values
      const errors = err.inner.reduce(
        (formError, innerError) =>
          setIn(formError, innerError.path, innerError.message),
        {},
      );
      // return a resolved promise with the error object
      return Promise.resolve(errors);
    });
};

export default {
  validateFormValues,
};