import styled from 'styled-components';
import SectionTitle from '../SectionTitle';
import TopPanel from '../TopPanel';
import Panel from '../common/panel';
import Container from '../common/container';
import CategoryList from './categoryList';
import PanelContent from './panelContent';
import DocumentsGrid from './Grid';

const MOCKED_DATA_CATEGORIES = [
  {
    key: 'investice',
    label: 'Investice',
    color: '#986D8E',
    bgColor: '#F1EBF0',
    items: [
      {
        key: 'fondy',
        label: 'Fondy',
        count: 105
      },
      {
        key: 'dluhopisy',
        label: 'Dluhopisy',
        count: 105
      },
    ]
  },
  {
    key: 'pojisteni',
    label: 'Pojištění',
    color: '#5F939A',
    bgColor: '#EAF0F1',
    items: [
      {
        key: 'zivotni',
        label: 'Životní',
        count: 105
      },
      {
        key: 'nezivotni',
        label: 'Neživotní',
        count: 105
      },
    ]
  },
  {
    key: 'uvery',
    label: 'Úvěry',
    color: '#83535F',
    bgColor: '#EFE8EA',
    items: [
      {
        key: 'hypoteky',
        label: 'Hypotéky',
        count: 105
      },
      {
        key: 'spotrebni-uvery',
        label: 'Spotřební úvěry',
        count: 105
      },
    ]
  }
];

const Subtitle = styled.div`
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #343A40;
  margin-bottom: 1.25rem;
`;

function Documents() {
  return (
    <>
    <TopPanel>
      <PanelContent />
    </TopPanel>
    <Container>
    <Panel>
      <CategoryList items={MOCKED_DATA_CATEGORIES} />
      <Subtitle>Poslední nahrané dokumenty</Subtitle>
      <DocumentsGrid />
    </Panel>
    </Container>
    </>
  );
}

export default Documents;