import { useEffect, useState, useMemo } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { getAll } from '../../../services/userService';
import DataTable from '../../DataTable';
import getColumns from './columns';
import SectionTitle from '../../SectionTitle';
import TopPanel from '../../TopPanel';
import Panel from '../../common/panel';
import Container from '../../common/container';
import PanelContent from './panelContent';
import { ADMIN_USER_NEW } from '../../../routes';

function Users() {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const history = useHistory();

  const columns = useMemo(() => getColumns(), []);

  const loadData = () => {
    setLoading(true);
    setError(false);
    getAll()
    .then( data => {
      setData(data);
    })
    .catch(error => {
      setError(error);
    })
    .then(() => setLoading(false));
  }
  useEffect(() => {
    loadData();
  }, []);

  const onAdd = () => {
    history.push(ADMIN_USER_NEW);
  }
  const onSearch = value => {
    console.log('search', value);
  }

  const handleTableStateChange = data => {
    // console.log('witke handleTableStateChange', data);
  }


  return (
    <>
    <TopPanel contentPadding="0.875rem 0">
      <PanelContent
        onAdd={onAdd}
        onSearch={onSearch}
         />
    </TopPanel>
    <Container>
    <Panel>
      <SectionTitle>
        Přehled uživatelů
      </SectionTitle>

      <DataTable
        columns={columns}
        data={data}
        loading={isLoading}
        error={!!error}
        onTableStateChange={handleTableStateChange}
      />
    </Panel>
    </Container>
    </>
  );
}

export default Users;